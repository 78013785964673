import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import { isAdmin } from 'service';
import moment from 'moment-timezone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogWrapper from 'components/shared/DialogWrapper';

const createProductURL = cs.BaseURL + '/product/create';
const merchantURL = cs.BaseURL + '/merchant/list';
const UserDetailURL = cs.BaseURL + '/user/detail';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class CreateProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            merchants: [],
            mc_code: '',
            name: '',
            code: '',
            category: cs.Category_PersonalAccount,
            desc_title: '',
            create_default: false
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        isAdmin() ? this.loadMerchants() : this.loadMerchantTls();
    }

    loadMerchants() {
        let queryString = `${merchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchants: data.data
                    });
                } else {
                    this.setState({
                        merchants: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchants: []
                });
            });
    }

    loadMerchantTls() {
        var user = localStorage.getItem(cs.System_Code + '-admin-user');
        if (user) {
            const username = JSON.parse(String(user)).name;

            const queryString = `${UserDetailURL}?name=${username}`;
            fetch(queryString, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        this.setState({
                            mc_code: data.data.mc_code
                        });
                    } else {
                        this.setState({
                            mc_code: 'Merchant'
                        });
                        if (
                            data &&
                            (data.code == cs.erAuthenticationFailed.code ||
                                data.code == cs.erAccountIsLocked.code)
                        ) {
                            sessionStorage.clear();
                            localStorage.clear();
                            window.location.reload();
                        }
                    }
                })
                .catch(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                });
        }
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.code) {
            window.alert('Please input information !');
            return;
        }
        let queryString = `${createProductURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                mc_code: this.state.mc_code,
                name: this.state.name,
                code: this.state.code,
                category: this.state.category,
                desc_title: this.state.desc_title,
                create_default: this.state.create_default ? 1 : 0
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Product is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/product',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => { });
    };

    handleChange = (name) => (event) => {
        // if (name == 'mc_auto_approve') this.setState({ mc_auto_approve: event.target.checked });
        // else
        if (name == 'create_default') this.setState({ create_default: event.target.checked });
        else
            this.setState({
                [name]: event.target.value
            });
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <DialogWrapper title={'Create Product'} {...this.props}>
                    <DialogContent>
                        {isAdmin() ? (
                            <>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Merchant (*)</InputLabel>
                                    <Select
                                        value={this.state.mc_code}
                                        onChange={this.handleChange('mc_code')}
                                        inputProps={{
                                            name: 'merchant',
                                            id: 'merchantId'
                                        }}
                                    >
                                        {this.state.merchants.map((item) => (
                                            <MenuItem value={item.code}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <FormControl fullWidth disabled>
                                <InputLabel htmlFor="create_user_merchant">
                                    {this.state.mc_code}
                                </InputLabel>
                                <Select>
                                    <MenuItem value={null}></MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_product_name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            label="Product Name"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_product_code"
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            label="Product Code"
                            type="code"
                        />
                        <FormControl fullWidth>
                            <InputLabel htmlFor="product_create_categoryId">
                                Category (*)
                            </InputLabel>
                            <Select
                                value={this.state.category}
                                onChange={this.handleChange('category')}
                                inputProps={{
                                    name: 'category',
                                    id: 'product_create_categoryId'
                                }}
                            >
                                <MenuItem value={cs.Category_PersonalAccount}>
                                    Personal Account
                                </MenuItem>
                                <MenuItem value={cs.Category_PersonalCard}>Personal Card</MenuItem>
                                <MenuItem value={cs.Category_PersonalLoan}>Personal Loan</MenuItem>
                                <MenuItem value={cs.Category_PersonalApp}>Personal App</MenuItem>

                                <MenuItem value={cs.Category_BusinessAccount}>
                                    Business Account
                                </MenuItem>
                                <MenuItem value={cs.Category_BusinessCard}>Business Card</MenuItem>
                                <MenuItem value={cs.Category_BusinessLoan}>Business Loan</MenuItem>
                                <MenuItem value={cs.Category_BusinessApp}>Business App</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_product_desc_title"
                            value={this.state.desc_title}
                            onChange={this.handleChange('desc_title')}
                            label="Desc Title"
                            type="code"
                        />
                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.create_default}
                                value={this.state.create_default}
                                onChange={this.handleChange('create_default')}
                                color="primary"
                            />
                            {this.state.create_default ? 'Tạo mặc định' : 'Tạo bằng tay'}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Create New
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </>
        );
    }
}

CreateProduct.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateProduct));
