import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import moment from 'moment-timezone';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Box, Divider, Icon, TableBody, TableCell, TableContainer, TableHead, TableRow, Table as CoreTable } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogWrapper from 'components/shared/DialogWrapper';

const URL = cs.BaseURL + '/product/detail';
const updateProductURL = cs.BaseURL + '/product/update';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 2 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class EditProduct extends React.Component {
    state = {
        tabNum: 0,
        id: this.props.match.params.id,
        mc_code: '',
        name: '',
        code: '',
        category: cs.Category_PersonalAccount,
        desc_title: '',
        desc_content: '',
        img_logo: '',
        img_banner: '',
        img_intro: '',
        img_steps: '',
        note_agent: '',
        note_cus: '',
        doc_types: '',
        reject_types: '',
        cancel_types: '',
        supplement_types: '',
        checkin_locs: '',
        collect_fields: '',
        card_return_types: '',
        img_product: '',
        effected_start: null,
        order_ref_require: false,
        order_ref_unique: false,
        allow_manual_create: false,
        locked: false,
        loading: true,
        expanded: false,

        columns: [
            { name: 'display', title: 'DISPLAY' },
            { name: 'value', title: 'VALUE' },
            { name: 'action', title: 'Options' }
        ],
        tableColumnExtensions: [
            { columnName: 'display', align: 'left', width: 350 },
            { columnName: 'value', align: 'left', width: 250 },
            { columnName: 'action', align: 'right' }
        ],
        display: '',
        value: '',

        columnscheckin: [
            { name: 'name', title: 'NAME' },
            { name: 'display', title: 'DISPLAY' },
            { name: 'code', title: 'CODE' },
            { name: 'is_require', title: 'REQUIRE' },
            { name: 'action', title: 'Options' }
        ],
        tableColumnExtensionscheckin: [
            { columnName: 'name', align: 'left', width: 200 },
            { columnName: 'display', align: 'left', width: 200 },
            { columnName: 'code', align: 'left', width: 150 },
            { columnName: 'is_require', align: 'center', width: 150 },
            { columnName: 'action', align: 'center' }
        ],

        columnscollect: [
            { name: 'name', title: 'NAME' },
            { name: 'value', title: 'VALUE' },
            { name: 'action', title: 'Options' }
        ],
        tableColumnExtensionscollect: [
            { columnName: 'name', align: 'left', width: 200 },
            { columnName: 'value', align: 'left', width: 200 },
            { columnName: 'action', align: 'center' }
        ],

        namecollect: '',
        valuecollect: '',

        nametable: '',
        codecheck: '',
        is_require: false,

        namedoc: '',
        displaydoc: '',
        codedoc: '',
        filetypes: ["image"],

        tableName: ''
    };
    constructor(props) {
        super(props);

        this.handleControlPointClick = this.handleControlPointClick.bind(this);
        this.handleDeleteRow = this.handleDeleteRow.bind(this);
        this.handeleDocSaveClick = this.handeleDocSaveClick.bind(this);
        this.handleDocDeleteClick = this.handleDocDeleteClick.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?new=true&id=${id}`;
        return queryString;
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    console.log(data.data)
                    this.setState({
                        mc_code: data.data.mc_code,
                        name: data.data.name,
                        code: data.data.code,
                        category: data.data.category,
                        desc_title: data.data.desc_title,
                        desc_content: data.data.desc_content,
                        img_logo: data.data.img_logo,
                        img_banner: data.data.img_banner,
                        img_intro: data.data.img_intro,
                        img_steps: data.data.img_steps,
                        note_agent: data.data.note_agent,
                        note_cus: data.data.note_cus,
                        doc_types: JSON.parse(data.data.doc_types),
                        reject_types: JSON.parse(data.data.reject_types),
                        cancel_types: JSON.parse(data.data.cancel_types),
                        supplement_types: JSON.parse(data.data.supplement_types),
                        checkin_locs: JSON.parse(data.data.checkin_locs),
                        card_return_types: JSON.parse(data.data.card_return_types),
                        collect_fields: JSON.parse(data.data.collect_fields),
                        effected_start: moment
                            .unix(data.data.effected_start)
                            .tz('Asia/Ho_Chi_Minh')
                            .format('YYYY-MM-DDTHH:mm'),
                        order_ref_require: data.data.order_ref_require,
                        order_ref_unique: data.data.order_ref_unique,
                        allow_manual_create: data.data.allow_manual_create,
                        locked: data.data.locked,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.code) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${updateProductURL}`;

        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('name', this.state.name);
        formData.append('code', this.state.code);
        formData.append('category', this.state.category);
        formData.append('desc_title', this.state.desc_title);
        formData.append('desc_content', this.state.desc_content);
        formData.append('note_agent', this.state.note_agent);
        formData.append('note_cus', this.state.note_cus);
        formData.append('doc_types', JSON.stringify(this.state.doc_types));
        formData.append('reject_types', JSON.stringify(this.state.reject_types));
        formData.append('cancel_types', JSON.stringify(this.state.cancel_types));
        formData.append('supplement_types', JSON.stringify(this.state.supplement_types));
        formData.append('checkin_locs', JSON.stringify(this.state.checkin_locs));
        formData.append('card_return_types', JSON.stringify(this.state.card_return_types));
        formData.append('collect_fields', JSON.stringify(this.state.collect_fields));
        formData.append('effected_start', moment(this.state.effected_start).unix());
        formData.append('order_ref_require', this.state.order_ref_require ? 1 : 0);
        formData.append('order_ref_unique', this.state.order_ref_unique ? 1 : 0);
        formData.append('allow_manual_create', this.state.allow_manual_create ? 1 : 0);
        formData.append('locked', this.state.locked ? 1 : 0);
        formData.append('img_product', this.state.img_product);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Product is updated !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/product',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        if (name == 'locked') this.setState({ locked: event.target.checked });
        else if (name == 'order_ref_require') this.setState({ order_ref_require: event.target.checked });
        else if (name == 'order_ref_unique') this.setState({ order_ref_unique: event.target.checked });
        else if (name == 'allow_manual_create') this.setState({ allow_manual_create: event.target.checked });
        else if (name == 'is_require') this.setState({ is_require: event.target.checked });
        else
            this.setState({
                [name]: event.target.value
            });
    };

    handlePanelChange = (panel) => (event, isExpanded) => {
        this.setState({
            expanded: (isExpanded ? panel : false)
        })
    };

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
        switch (value) {
            case 4:
                this.setState({ tableName: 'reject' });
                break;
            case 5:
                this.setState({ tableName: 'cancel' });
                break;
            case 6:
                this.setState({ tableName: 'supplement' });
                break;
            case 7:
                this.setState({ tableName: 'checkinlocs' });
                break;
            case 8:
                this.setState({ tableName: 'card' });
                break;
            case 9:
                this.setState({ tableName: 'collect' });
                break;
            default: break;
        }
    };

    handeleDocSaveClick = (item) => {
        if (item.children == null) return alert("Can't add children to doc")
        var newchild = { name: this.state.namedoc, display: this.state.displaydoc, code: this.state.codedoc, filetypes: [this.state.filetypes] }
        if (newchild.name == "" || newchild.display == "" || newchild.code == "") return alert("Please fill in the input before save")
        item.children = [...item.children, newchild]
        alert("It may take a while to update your request")
    }

    handleDocDeleteClick = (item, value) => {
        var array = this.state.doc_types.children;
        if (value == null) {
            const index = array.indexOf(item);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            item = item.children
            const index = item.indexOf(value);
            if (index > -1) {
                item.splice(index, 1);
            }
        }
        alert("It may take a while to update your request")
    }

    handleControlPointClick = name => {
        var NewType = { display: this.state.display, value: this.state.value };
        var NewLocs = { name: this.state.nametable, display: this.state.display, code: this.state.codecheck, is_require: this.state.is_require };
        var NewDoc = { name: this.state.nametable, display: this.state.display, children: [] }
        var NewCollect = { name: this.state.namecollect, value: this.state.valuecollect };
        switch (name) {
            case 'reject':
                if (this.state.reject_types == null)
                    this.setState({
                        reject_types: [NewType]
                    })
                else this.setState(prevState => ({
                    reject_types: [...prevState.reject_types, NewType]
                }));
                break;
            case 'cancel':
                if (this.state.cancel_types == null)
                    this.setState({
                        cancel_types: [NewType]
                    })
                else this.setState(prevState => ({
                    cancel_types: [...prevState.cancel_types, NewType]
                }));
                break;
            case 'supplement':
                if (this.state.supplement_types == null)
                    this.setState({
                        supplement_types: [NewType]
                    })
                else this.setState(prevState => ({
                    supplement_types: [...prevState.supplement_types, NewType]
                }));
                break;
            case 'checkinlocs':
                if (this.state.checkin_locs == null)
                    this.setState({
                        checkin_locs: [NewLocs]
                    })
                else this.setState(prevState => ({
                    checkin_locs: [...prevState.checkin_locs, NewLocs]
                }));
                break;
            case 'doc':
                if (this.state.doc_types == null)
                    this.setState({
                        doc_types: { "name": "root", "children": [NewDoc] }
                    })
                else this.setState(prevState => ({
                    doc_types: {
                        ...prevState.doc_types,
                        children: [...this.state.doc_types.children, NewDoc]
                    }
                }));
                break;
            case 'card':
                if (this.state.card_return_types == null)
                    this.setState({
                        card_return_types: [NewType]
                    })
                else this.setState(prevState => ({
                    card_return_types: [...prevState.card_return_types, NewType]
                }));
                break;
            case 'collect':
                var collect_fields = [...this.state.collect_fields];
                collect_fields[0].fields[0].options.push(NewCollect);
                this.setState({
                    collect_fields
                })
                console.log(collect_fields[0].fields[0].options);
                break;
            default: break;
        }
    }

    handleDeleteRow = props => {
        switch (this.state.tableName) {
            case 'reject':
                this.setState({
                    reject_types: this.state.reject_types.filter(item => item !== props)
                });
                break;
            case 'cancel':
                this.setState({
                    cancel_types: this.state.cancel_types.filter(item => item !== props)
                });
                break;
            case 'supplement':
                this.setState({
                    supplement_types: this.state.supplement_types.filter(item => item !== props)
                });
                break;
            case 'checkinlocs':
                this.setState({
                    checkin_locs: this.state.checkin_locs.filter(item => item !== props)
                });
                break;
            case 'card':
                this.setState({
                    card_return_types: this.state.card_return_types.filter(item => item !== props)
                });
                break;
            case 'collect':
                var collect_fields = [...this.state.collect_fields];
                const index = collect_fields[0].fields[0].options.indexOf(props);
                if (index > -1) {
                    collect_fields[0].fields[0].options.splice(index, 1);
                }
                this.setState({
                    collect_fields: collect_fields
                })
                break;
            default: break;
        }
    }

    CellComponent = props => {
        const { column, row } = props;
        if (column.name === 'action') {
            return (
                <Table.Cell
                    {...props}
                    style={{
                        padding: 2,
                        justifyContent: 'center'
                    }}>
                    {this.ActionCell(props)}
                </Table.Cell>
            )
        }

        if (column.name === 'is_require') {
            return (
                <Table.Cell
                    {...props}
                    style={{
                        padding: 2,
                        justifyContent: 'center'
                    }}>
                    {row.is_require == true ? 'Require' : ''}
                </Table.Cell>
            )
        }

        return (
            <Table.Cell
                {...props}
                style={{
                    padding: 2
                }}
            />
        );
    };

    ActionCell = props => {
        return (
            <span>
                <Button
                    color="secondary"
                    style={{
                        margin: 0,
                        padding: 0
                    }}
                    onClick={() => this.handleDeleteRow(props.row)}
                >
                    <Icon>remove_circle</Icon>
                </Button>
            </span>
        );
    }

    handleDropZoneChange(files) {
        this.setState({
            img_product: files[0]
        });
    };

    render() {
        const { classes } = this.props;
        const { tabNum } = this.state;

        const {
            columns,
            columnscheckin,
            columnscollect,
            tableColumnExtensions,
            tableColumnExtensionscheckin,
            tableColumnExtensionscollect,
            doc_types,
            collect_fields,
        } = this.state;


        return (
            <div>
                <DialogWrapper title={' Update Product'} {...this.props} width={'lg'}>
                    <DialogContent>
                        <AppBar position="static">
                            <Tabs
                                value={tabNum}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="General" />
                                <Tab label="Content" />
                                <Tab label="Config" />
                                <Tab label="Documents" />
                                <Tab label="Reject Reasons" />
                                <Tab label="Cancel Reasons" />
                                <Tab label="Supplement Resons" />
                                <Tab label="Checkin Locs" />
                                <Tab label="Card Return" />
                                <Tab label="Attributes" />
                            </Tabs>
                        </AppBar>
                        {tabNum === 0 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    disabled
                                    margin="dense"
                                    id="edit_product_mc_code"
                                    required={true}
                                    value={this.state.mc_code}
                                    onChange={this.handleChange('mc_code')}
                                    label="Merchant"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_product_name"
                                    required={true}
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    label="Product Name"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    disabled
                                    margin="dense"
                                    id="edit_product_code"
                                    required={true}
                                    value={this.state.code}
                                    onChange={this.handleChange('code')}
                                    label="Product Code"
                                    type="text"
                                />
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_product_categoryId">Category (*)</InputLabel>
                                    <Select
                                        value={this.state.category}
                                        onChange={this.handleChange('category')}
                                        inputProps={{
                                            name: 'category',
                                            id: 'edit_product_categoryId'
                                        }}
                                    >
                                        <MenuItem value={cs.Category_PersonalAccount}>Personal Account</MenuItem>
                                        <MenuItem value={cs.Category_PersonalCard}>Personal Card</MenuItem>
                                        <MenuItem value={cs.Category_PersonalLoan}>Personal Loan</MenuItem>
                                        <MenuItem value={cs.Category_PersonalApp}>Personal App</MenuItem>

                                        <MenuItem value={cs.Category_BusinessAccount}>Business Account</MenuItem>
                                        <MenuItem value={cs.Category_BusinessCard}>Business Card</MenuItem>
                                        <MenuItem value={cs.Category_BusinessLoan}>Business Loan</MenuItem>
                                        <MenuItem value={cs.Category_BusinessApp}>Business App</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_product_desc_title"
                                    required={true}
                                    value={this.state.desc_title}
                                    onChange={this.handleChange('desc_title')}
                                    label="Desc Title"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_product_desc_content"
                                    required={true}
                                    value={this.state.desc_content}
                                    onChange={this.handleChange('desc_content')}
                                    label="Desc Content"
                                    multiline
                                    rowsMax="4"
                                    type="text"
                                />
                            </TabContainer>
                        )}
                        {tabNum === 1 && (
                            <TabContainer>
                                <Paper className={classes.grid_Container}>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={
                                            this.state.img_product && this.state.img_product.name
                                                ? this.state.img_product.name
                                                : 'Upload file ảnh logo và banner'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}
                                        showPreviewsInDropzone={true}
                                        onChange={this.handleDropZoneChange}
                                        dropZoneClass={classes.dropZone}
                                    />
                                </Paper>
                            </TabContainer>
                        )}
                        {tabNum === 2 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_product_effected_start"
                                    value={this.state.effected_start}
                                    onChange={this.handleChange('effected_start')}
                                    label="Start Date"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <div fullWidth>
                                    <Switch
                                        checked={this.state.order_ref_require}
                                        value={this.state.order_ref_require}
                                        onChange={this.handleChange('order_ref_require')}
                                        color="primary"
                                    />
                                    {this.state.order_ref_require
                                        ? 'Merchant Mã tham chiếu (đối tác) là bắt buộc'
                                        : 'Merchant Order Ref is not necessary'}
                                </div>
                                {this.state.order_ref_require ? (
                                    <div fullWidth>
                                        <Switch
                                            checked={this.state.order_ref_unique}
                                            value={this.state.order_ref_unique}
                                            onChange={this.handleChange('order_ref_unique')}
                                            color="primary"
                                        />
                                        {this.state.order_ref_unique
                                            ? 'Merchant Order Ref must be unique by merchant'
                                            : 'Merchant Order Ref is free-input'}
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div fullWidth>
                                    <Switch
                                        checked={this.state.allow_manual_create}
                                        value={this.state.allow_manual_create}
                                        onChange={this.handleChange('allow_manual_create')}
                                        color="primary"
                                    />
                                    {this.state.allow_manual_create ? 'Manual Create' : 'Can not create manually'}
                                </div>
                                <div fullWidth className={classes.locked_button}>
                                    <Switch
                                        checked={this.state.locked}
                                        value={this.state.locked}
                                        onChange={this.handleChange('locked')}
                                        color="primary"
                                    />
                                    {this.state.locked ? 'Locked' : 'Unlocked'}
                                </div>
                            </TabContainer>
                        )}
                        {tabNum === 3 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_name"
                                                required={true}
                                                onChange={this.handleChange('nametable')}
                                                label="Name"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('doc')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {doc_types != null ? (
                                    <div className={classes.root}>
                                        {Object.values(doc_types.children).map((item, index) => {
                                            return (
                                                <Accordion expanded={this.state.expanded === `panel${index}`} onChange={this.handlePanelChange(`panel${index}`)}>
                                                    <AccordionSummary
                                                        expandIcon={<Icon>expand_more</Icon>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className={classes.heading}>{item.display}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {item.children == null ? (
                                                            <TableContainer component={Paper}>
                                                                <CoreTable aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="center">Name</TableCell>
                                                                            <TableCell align="center">Display</TableCell>
                                                                            <TableCell align="center">Code</TableCell>
                                                                            <TableCell align="center">Filetypes</TableCell>
                                                                            <TableCell align="center"></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow key={item.name}>
                                                                            <TableCell align="center">{item.name}</TableCell>
                                                                            <TableCell align="center">{item.display}</TableCell>
                                                                            <TableCell align="center">{item.code}</TableCell>
                                                                            <TableCell align="center">{item.filetypes}</TableCell>
                                                                            <TableCell align="center">
                                                                                <Button
                                                                                    color="secondary"
                                                                                    style={{
                                                                                        margin: 0,
                                                                                        padding: 0
                                                                                    }}
                                                                                    onClick={() => this.handleDocDeleteClick(item)}
                                                                                >
                                                                                    <Icon>remove_circle</Icon>
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </CoreTable>
                                                            </TableContainer>
                                                        ) : (
                                                            <div>
                                                                <Typography display='block' gutterBottom className={classes.heading} align='center'>{item.name}</Typography>
                                                                <TableContainer component={Paper}>
                                                                    <CoreTable aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="center">Name</TableCell>
                                                                                <TableCell align="center">Display</TableCell>
                                                                                <TableCell align="center">Code</TableCell>
                                                                                <TableCell align="center">Filetypes</TableCell>
                                                                                <TableCell align="center"></TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {item.children.map(value => {
                                                                                return (
                                                                                    <TableRow key={value.name}>
                                                                                        <TableCell align="center">{value.name}</TableCell>
                                                                                        <TableCell align="center">{value.display}</TableCell>
                                                                                        <TableCell align="center">{value.code}</TableCell>
                                                                                        <TableCell align="center">{value.filetypes}</TableCell>
                                                                                        <TableCell align="center">
                                                                                            <Button
                                                                                                color="secondary"
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    padding: 0
                                                                                                }}
                                                                                                onClick={() => this.handleDocDeleteClick(item, value)}
                                                                                            >
                                                                                                <Icon>remove_circle</Icon>
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            })}
                                                                        </TableBody>
                                                                    </CoreTable>
                                                                </TableContainer>
                                                            </div>
                                                        )}
                                                    </AccordionDetails>
                                                    <AccordionDetails>
                                                        <form className={classes.search_container} noValidate autoComplete="off">
                                                            <TextField
                                                                id="name"
                                                                label="Name"
                                                                required
                                                                className={classes.search_field}
                                                                value={this.state.namedoc}
                                                                onChange={this.handleChange('namedoc')}
                                                                margin="normal"
                                                            />
                                                            <TextField
                                                                id="display"
                                                                label="Display"
                                                                required
                                                                className={classes.search_field}
                                                                value={this.state.displaydoc}
                                                                onChange={this.handleChange('displaydoc')}
                                                                margin="normal"
                                                            />
                                                            <TextField
                                                                id="code"
                                                                label="Code"
                                                                required
                                                                className={classes.search_field}
                                                                value={this.state.codedoc}
                                                                onChange={this.handleChange('codedoc')}
                                                                margin="normal"
                                                            />
                                                            <FormControl className={classes.search_field}>
                                                                <InputLabel htmlFor="filetypes">File Types</InputLabel>
                                                                <Select
                                                                    value={this.state.filetypes}
                                                                    onChange={this.handleChange('filetypes')}
                                                                    inputProps={{
                                                                        name: 'filetypes',
                                                                        id: 'filetypes'
                                                                    }}
                                                                >
                                                                    <MenuItem value={null}><em /></MenuItem>
                                                                    <MenuItem value={'image'}>image</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </form>
                                                    </AccordionDetails>
                                                    <Divider />
                                                    <AccordionActions>
                                                        <Button size="small" onClick={this.handlePanelChange(`panel${index}`)}>Cancel</Button>
                                                        <Button size="small" color="primary" onClick={() => this.handeleDocSaveClick(item)}>Save</Button>
                                                    </AccordionActions>
                                                </Accordion>
                                            )
                                        })}
                                    </div>) : null}
                            </TabContainer>
                        )}
                        {tabNum === 4 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_value"
                                                required={true}
                                                onChange={this.handleChange('value')}
                                                label="Value"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('reject')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {this.state.reject_types == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={this.state.reject_types} columns={columns} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensions}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                        {tabNum === 5 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_value"
                                                required={true}
                                                onChange={this.handleChange('value')}
                                                label="Value"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('cancel')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {this.state.cancel_types == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={this.state.cancel_types} columns={columns} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensions}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                        {tabNum === 6 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_value"
                                                required={true}
                                                onChange={this.handleChange('value')}
                                                label="Value"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('supplement')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {this.state.supplement_types == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={this.state.supplement_types} columns={columns} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensions}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                        {tabNum === 7 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_name"
                                                required={true}
                                                onChange={this.handleChange('nametable')}
                                                label="Name"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_code"
                                                required={true}
                                                onChange={this.handleChange('codecheck')}
                                                label="Code"
                                                type="text"
                                            />

                                            <div fullWidth className={classes.locked_button}>
                                                <Switch
                                                    checked={this.state.is_require}
                                                    value={this.state.is_require}
                                                    onChange={this.handleChange('is_require')}
                                                    color="primary"
                                                />
                                                {this.state.is_require ? 'Require' : 'Not Require'}
                                            </div>
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('checkinlocs')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {this.state.checkin_locs == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={this.state.checkin_locs} columns={columnscheckin} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensionscheckin}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                        {tabNum === 8 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('display')}
                                                label="Display"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_value"
                                                required={true}
                                                onChange={this.handleChange('value')}
                                                label="Value"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('card')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {this.state.card_return_types == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={this.state.card_return_types} columns={columns} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensions}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                        {tabNum === 9 && (
                            <TabContainer>
                                <div style={{ width: '100%' }}>
                                    <Box display="flex" p={1}>
                                        <Box p={1} flexGrow={1}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_display"
                                                required={true}
                                                onChange={this.handleChange('namecollect')}
                                                label="Name"
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="add_value"
                                                required={true}
                                                onChange={this.handleChange('valuecollect')}
                                                label="Value"
                                                type="text"
                                            />
                                        </Box>
                                        <Box p={1}>
                                            <Button
                                                style={{
                                                    marginTop: 50,
                                                    padding: 0,
                                                    minWidth: 48
                                                }}
                                                color="primary"
                                                onClick={() => this.handleControlPointClick('collect')}
                                            >
                                                <Icon>control_point</Icon>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                                {collect_fields[0].fields[0].options == null ?
                                    (
                                        null
                                    ) : (
                                        <Paper className={classes.grid_Container}>
                                            <Grid rows={collect_fields[0].fields[0].options} columns={columnscollect} getRowId={getRowId}>
                                                <VirtualTable
                                                    tableComponent={TableComponent}
                                                    columnExtensions={tableColumnExtensionscollect}
                                                    cellComponent={this.CellComponent}
                                                    rowComponent={RowComponent}
                                                />
                                                <TableHeaderRow className={classes.grid_header}
                                                    rowComponent={HeaderRowComponent}
                                                    cellComponent={HeaderCellComponent} />
                                            </Grid>
                                        </Paper>
                                    )}
                            </TabContainer>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </DialogWrapper >
            </div >
        );
    }
}

const getRowId = row => row.id;

EditProduct.propTypes = {
    classes: PropTypes.object.isRequired
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                paddingLeft: 40,
                alignContent: 'center'
            }}
        />
    );
};

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withToastManager(withStyles(styles)(EditProduct));
