import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {
    Table
} from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment-timezone';

import cs from 'const';
import CreateProduct from 'components/pages/product/CreateProduct';
import EditProduct from 'components/pages/product/EditProduct';
import ProductAgents from 'components/pages/product/agent/ProductAgents';
import DeleteProduct from 'components/pages/product/DeleteProduct';
import ImportProduct from 'components/pages/product/ImportProduct';
import DataTable from 'components/shared/DataTable';

const URL = cs.BaseURL + '/product/list';

function formatValue(value) {
    if (value && value.locked) value.locked = 'Locked';
    else value.locked = '';

    if (value && value.effected_start)
        value.effected_start = moment
            .unix(value.effected_start)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DDTHH:mm');

    if (value && value.category) {
        if (value.category == cs.Category_PersonalAccount) value.category = 'Personal Account';
        if (value.category == cs.Category_PersonalCard) value.category = 'Personal Card';
        if (value.category == cs.Category_PersonalLoan) value.category = 'Personal Loan';
        if (value.category == cs.Category_PersonalApp) value.category = 'Personal App';

        if (value.category == cs.Category_BusinessAccount) value.category = 'Business Account';
        if (value.category == cs.Category_BusinessCard) value.category = 'Business Card';
        if (value.category == cs.Category_BusinessLoan) value.category = 'Business Loan';
        if (value.category == cs.Category_BusinessApp) value.category = 'Business App';
    }

    return value;
}

class ProductList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'mc_code', title: 'Merchant code' },
                { name: 'name', title: 'Product' },
                { name: 'code', title: 'Code' },
                // { name: 'category', title: 'Category' },
                { name: 'effected_start', title: 'Start' },
                { name: 'locked', title: ' ' },
                { name: 'action', title: 'Options' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'mc_code', align: 'center', width: 200 },
                { columnName: 'name', align: 'left', width: 150 },
                { columnName: 'code', align: 'left', width: 200 },
                // { columnName: 'category', align: 'center', width: 200 },
                { columnName: 'effected_start', align: 'center', width: 200 },
                { columnName: 'locked', align: 'center', width: 150 },
                { columnName: 'action', align: 'left', width: 160 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'id', direction: 'desc' }],
            showImportProduct: false,
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);

        this.handleShowImportProduct = this.handleShowImportProduct.bind(this);
        this.handleCloseImportProduct = this.handleCloseImportProduct.bind(this);

    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 3000 * 10);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const { pageSize, currentPage, sorting } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });

        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleShowImportProduct = () => {
        this.setState({ showImportProduct: true });
    };

    handleCloseImportProduct = () => {
        this.setState({ showImportProduct: false });
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New Product"
                    component={Link}
                    to={'/product/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Create New Product</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>

                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import Product"
                    component={Link}
                    to={'/import/product'}
                >
                    <Icon>cloud_upload</Icon>
                    <span style={{ marginLeft: 10 }}>Import Product</span>
                </Button>{' '}

                <DataTable
                    rows={rows}
                    columns={columns}
                    columnWidths={tableColumnExtensions}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    loading={loading}
                    sorting={sorting}
                    totalCount={totalCount}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                    changeSorting={this.changeSorting}
                    CellComponent={CellComponent}
                />

                <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
                    <Route exact path="/product/create" component={CreateProduct} />
                    <Route path="/product/:id/agents" component={ProductAgents} />
                    <Route exact path="/product/edit/:id" component={EditProduct} />
                    <Route exact path="/product/delete/:id" component={DeleteProduct} />
                    <ImportProduct open={this.state.showImportProduct} onClose={this.handleCloseImportProduct} {...this.props} />
                </ToastProvider>
            </div>
        );
    }
}

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 2,
                    justifyContent: 'center'
                }}
            >
                <span>
                    <Button
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        color="primary"
                        component={Link}
                        to={'/product/' + this.props.row.id + '/agents'}
                    >
                        <Icon>list_alt</Icon>
                    </Button>
                    <Button
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        color="primary"
                        component={Link}
                        to={'/product/edit/' + this.props.row.id}
                    >
                        <Icon>edit</Icon>
                    </Button>
                    <Button
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        color="secondary"
                        component={Link}
                        to={'/product/delete/' + this.props.row.id}
                    >
                        <Icon>remove_circle</Icon>
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2
            }}
        />
    );
};

ProductList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_adr_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: '100%',
        minWidth: 120
    }
});

export default withStyles(styles)(ProductList);
