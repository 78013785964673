export default {
    System_Id: 2,
    BaseURL: process.env.REACT_APP_API_ADMIN_URL,
    System_Code: process.env.REACT_APP_SYSTEM_CODE,
    System_Name: process.env.REACT_APP_SYSTEM_NAME,
    System_Domain: process.env.REACT_APP_SYSTEM_DOMAIN,
    RecaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,

    OrderStatus_Canceled: -1,
    OrderStatus_Ready: 11,
    OrderStatus_AgentAssigned: 12,
    OrderStatus_AgentRejected: 13,
    OrderStatus_AgentAccepted: 21,
    OrderStatus_Submitted: 22,
    OrderStatus_OpInReview: 32,
    OrderStatus_OpRejected: 33,
    OrderStatus_OpApproved: 34,
    OrderStatus_OPSupplementRequest: 35,
    OrderStatus_MerchantInReview: 41,
    OrderStatus_MerchantRejected: 42,
    OrderStatus_MerchantApproved: 43,
    OrderStatus_MerchantSupplementRequest: 44,

    UserType_Admin: 'Admin',
    UserType_OP: 'OP',
    UserType_HR: 'HR',
    UserType_Merchant: 'Merchant',
    UserType_Telesale: 'Telesale',
    UserType_Marketing: 'Marketing',
    UserType_Debt: 'Debt',

    Role_Sys_Admin: 1,
    Role_Mc_Board_Of_Control_Member: 10,
    Role_Admin: 11,
    Role_OP_User: 12,
    Role_OP_Leader: 13,
    Role_OP_Manager: 14,
    Role_Agent_Order: 21,
    Role_Agent_Card: 22,
    Role_Agent_Telesale: 23,
    Role_Merchant_User: 41,
    Role_Merchant_Leader: 42,
    Role_Merchant_Manager: 43,
    Role_HR_User: 51,
    Role_HR_Leader: 52,
    Role_HR_Manager: 53,
    Role_News_User: 61,
    Role_News_Leader: 62,
    Role_Document_HubUser: 71,
    Role_Document_HubLeader: 72,
    Role_Document_HubManager: 73,
    Role_Document_BankUser: 74,
    Role_Document_BankLeader: 75,
    Role_Document_BankManager: 76,
    Role_Card_HubUser: 81,
    Role_Card_HubLeader: 82,
    Role_Card_HubManager: 83,
    Role_Card_BankUser: 84,
    Role_Card_BankLeader: 85,
    Role_Card_BankManager: 86,
    
    Role_Merchant_Admin: 90,
    Role_Telesale_OP_User: 91,
    Role_Telesale_OP_Leader: 92,            //truong phong
    Role_Telesale_OP_Manager: 93,           //giam doc
    Role_Telesale_Merchant_User: 94,
    Role_Telesale_Merchant_Leader: 95,      //truong phong
    Role_Telesale_Merchant_Manager: 96,     //giam doc
    Role_Telesale_Mc_Branch_Director: 97,

    Role_Marketing_OP_User: 101,
    Role_Marketing_OP_Leader: 102,
    Role_Marketing_OP_Manager: 103,
    Role_Marketing_Merchant_User: 104,
    Role_Marketing_Merchant_Leader: 105,
    Role_Marketing_Merchant_Manager: 106,

    Category_PersonalAccount: 1,
    Category_PersonalCard: 2,
    Category_PersonalLoan: 3,
    Category_PersonalSecret: 4,
    Category_PersonalApp: 5,
    Category_BusinessAccount: 11,
    Category_BusinessCard: 12,
    Category_BusinessLoan: 13,
    Category_BusinessSecret: 14,

    EntityType_Order: 1,
    EntityType_Product: 2,
    EntityType_Info: 3,

    NotifyType_Agent: 'NotifyAgent',
    NotifyType_OP: 'NotifyOP',
    NotifyType_MC: 'NotifyMC',
    NotifyType_HR: 'NotifyHR',

    NotifyType_Order_Canceled: -1,
    NotifyType_Order_AgentAssigned: 11,
    NotifyType_Order_AgentAccepted: 12,
    NotifyType_Order_AgentPlanned: 13,
    NotifyType_Order_Submitted: 21,
    NotifyType_Order_OPAssigned: 30,
    NotifyType_Order_OPApproved: 31,
    NotifyType_Order_OPRejected: 32,
    NotifyType_Order_OPSupplementRequest: 33,
    NotifyType_Order_MerchantAssigned: 40,
    NotifyType_Order_MerchantApproved: 41,
    NotifyType_Order_MerchantRejected: 42,
    NotifyType_Order_MerchantSupplementRequest: 43,
    NotifyType_Order_Commented: 51,

    OrderActivity_Created: 'OrderCreated',
    OrderActivity_Modified: 'OrderModified',
    OrderActivity_AssignToAgent: 'AssignToAgent',
    OrderActivity_AgentAccept: 'AgentAccept',
    OrderActivity_AgentReject: 'AgentReject',
    OrderActivity_AgentMakePlan: 'AgentMakePlan',
    OrderActivity_Submit: 'AgentSubmit',
    OrderActivity_OPApprove: 'OPApprove',
    OrderActivity_OPReject: 'OPReject',
    OrderActivity_OPSupplementRequest: 'OPSupplementRequest',
    OrderActivity_MerchantApprove: 'MerchantApprove',
    OrderActivity_MerchantReject: 'MerchantReject',
    OrderActivity_MerchantSupplementRequest: 'MerchantSupplementRequest',

    OrderActivity_OPCanceled: 'OPCancel',
    OrderActivity_AgentCanceled: 'AgentCancel',

    Author_System: 'System',
    Author_OP: 'OP',
    Author_HR: 'HR',
    Author_Merchant: 'Merchant',
    Author_Agent: 'Agent',

    Succeed: { code: 100, content: 'Succeed' },

    erAgentCodeExisted: { code: 105, content: 'Mã DSA đã tồn tại' },
    erAgentExisted: { code: 106, content: 'DSA đã tồn tại' },
    erAgentNotExisted: { code: 107, content: 'DSA không tồn tại' },
    erAgentInvalid: { code: 108, content: 'DSA không hợp lệ' },
    erAgentIsLocked: { code: 109, content: 'DSA đã bị khóa' },
    erAgentIsNotAllocable: { code: 110, content: 'DSA đang không rãnh' },

    erMerchantExisted: { code: 201, content: 'Đối tác đã tồn tại' },
    erMerchantNotExisted: { code: 202, content: 'Đối tác không tồn tại' },
    erMerchantInvalid: { code: 203, content: 'Đối tác không hợp lệ' },
    erMerchantIsLocked: { code: 204, content: 'Đối tác đã bị khóa tài khoản' },

    erCustomerExisted: { code: 301, content: 'Khách hàng đã tồn tại' },
    erCustomerNotExisted: { code: 302, content: 'Khách hàng không tồn tại' },
    erCustomerInvalid: { code: 303, content: 'Khách hàng không hợp lệ' },
    erCannotCreateCustomer: { code: 304, content: 'Không thể khởi tạo khách hàng' },

    erUserExisted: { code: 401, content: 'Tài khoản User đã tồn tại' },
    erUserNotExisted: { code: 402, content: 'Tài khoản User không tồn tại' },
    erUserInvalid: { code: 403, content: 'Tài khoản User không hợp lệ' },

    erAccountIsLocked: { code: 405, content: 'Tài khoản User đã bị khóa' },
    erUserPhoneExisted: { code: 406, content: 'Số điện thoại đã tồn tại' },
    erUserPermExisted: { code: 407, content: 'Phân quyền đã tồn tại' },
    erUserCodeExisted: { code: 408, content: 'User Code đã tồn tại' },

    erProductExisted: { code: 501, content: 'Sản phẩm đã tồn tại' },
    erProductNotExisted: { code: 502, content: 'Sản phẩm không tồn tại' },
    erProductInvalid: { code: 503, content: 'Sản phẩm không hợp lệ' },
    erProductIsLocked: { code: 504, content: 'Sản phẩm đã bị khóa' },
    erProductExerciseNotExisted: { code: 505, content: 'Bài trắc nghiệm đã tồn tại' },

    erOrderExisted: { code: 601, content: 'Hồ sơ đã tồn tại' },
    erOrderNotExisted: { code: 602, content: 'Hồ sơ không tồn tại' },
    erOrderInvalid: { code: 603, content: 'Hồ sơ không hợp lệ' },
    erOrderIsLocked: { code: 604, content: 'Hồ sơ đã bị khóa' },
    erOrderDocNotExisted: { code: 605, content: 'Tài liệu không tồn tại' },
    erOrderRefRequired: { code: 607, content: 'Mã tham chiếu (đối tác) là bắt buộc' },
    erOrderRefIsExisted: { code: 608, content: 'Mã tham chiếu (đối tác) đã tồn tại' },
    erOrderCannotSubmit: { code: 609, content: 'Không thể đẩy hồ sơ về hệ thống' },
    erOrderInvalidDocs: { code: 610, content: 'Thiếu tài liệu !' },
    erOrderInvalidCusInfo: { code: 611, content: 'Thông tin hồ sơ không hợp lệ !' },
    erOrderCusPhoneIsExisted: { code: 612, content: 'Số điện thoại khách hàng đã tồn tại' },
    erOrderCusNationalIDIsExisted: { code: 612, content: 'CMND/CCCD của khách hàng đã tồn tại' },

    erCannotConnectDatabase: { code: 701, content: 'Không thể kết nối vào cơ sở dữ liệu' },
    erCannotQueryToDatabase: { code: 702, content: 'Không thể truy xuất dữ liệu' },
    erCannotCreateObject: { code: 703, content: 'Không thể tạo dữ liệu' },
    erInvalidRequest: { code: 704, content: 'Yêu cầu không hợp lệ' },
    erAuthenticationFailed: { code: 705, content: 'Xác thực thất bại' },
    erObjectExisted: { code: 706, content: 'Dữ liệu đã tồn tại' },
    erObjectNotExisted: { code: 707, content: 'Dữ liệu không tồn tại' },
    erObjectIsLocked: { code: 708, content: 'Dữ liệu bị khóa' },
    erPermissionDenied: { code: 709, content: 'Không có quyền truy cập' },
    erUploadFailed: { code: 711, content: 'Upload thất bại' },
    erInvalidOTP: { code: 712, content: 'Sai mã OTP' },
    erCannotModifyObject: { code: 713, content: 'Không thể chỉnh sửa' },
    erCannotCreateLog: { code: 714, content: 'Không thể tạo log' },
    erNothingToUpdate: { code: 715, content: 'Không có gì mới để cập nhật' },

    erProductCodeCannotEmpty: { code: 801, content: 'Mã sản phẩm không được trống' },
    erPhoneNumberCannotEmpty: { code: 802, content: 'Số điện thoại không được trống' },
    erCustomerNameCannotEmpty: { code: 803, content: 'Tên khách hàng không được trống' },
    erCustomerGenderCannotEmpty: { code: 804, content: 'Giới tính khách hàng không được trống' },
    erStateCannotEmpty: { code: 805, content: 'Tỉnh/TP không được trống' },
    erDistrictCannotEmpty: { code: 806, content: 'Quận / huyện không được trống' },
    erWardCannotEmpty: { code: 807, content: 'Phường xã không được trống' },
    erInvalidState: { code: 808, content: 'Tỉnh/TP không hợp lệ' },
    erInvalidDistrict: { code: 809, content: 'Quận / huyện không hợp lệ' },
    erRefCannotEmpty: { code: 810, content: 'Ref Number can not be empty' },
    erOrderCodeCannotEmpty: { code: 811, content: 'Mã Code không đựợc trống' },
    erCustomerHasProcessingOrder: { code: 812, content: 'Khách hàng có hồ sơ đang xử lý.' },
    erPhoneNumberInvalid: { code: 813, content: 'Số điện thoại không hợp lệ' },
    erNationalIdInvalid: { code: 814, content: 'CMND/CCCD không hợp lệ' },
    erNationalIdDateInvalid: { code: 815, content: 'Ngày cấp CMND/CCCD không hợp lệ' },
    erNationalIdPlaceInvalid: { code: 816, content: 'Nơi cấp CMND/CCCD không hợp lệ' },
    erEMailInvalid: { code: 817, content: 'email không hợp lệ' },
    erEMailCannotEmpty: { code: 818, content: 'email không được trống' },
    erLeaderCannotEmpty: { code: 819, content: 'Trưởng nhóm không được trống' },
    erInvalidEducationType: { code: 820, content: 'Trình độ học vấn không hợp lệ' },
    erInvalidMaritalStatus: { code: 821, content: 'Tình trạng hôn nhân không hợp lệ' },
    erInvalidRegion: { code: 822, content: 'Miền không hợp lệ' },
    erInvalidSubRegion: { code: 823, content: 'Vùng không hợp lệ' },
    erInvalidDisburmentChannel: { code: 824, content: 'Hình thức nhận lương không hợp lệ' },

    erOrderOfferExisted: { code: 901, content: 'Offer đã tồn tại' },
    erOrderOfferNotExisted: { code: 902, content: 'Offer không tồn tại' },
    erOrderOfferInvalid: { code: 903, content: 'Offer không hợp lệ' },
    erMerchantRefCodeExisted: { code: 904, content: 'Mã hồ sơ (đối tác) đã tồn tại' },

    erInvalidContractType: { code: 911, content: 'Loại hợp đồng không hợp lệ' },

    erInvalidDocStatus: { code: 912, content: 'Trạng thái chứng từ không hợp lệ' },
    erInvalidRefType: { code: 913, content: 'Loại tham chiếu không hợp lệ' },
    erBirthdayCannotEmpty: { code: 914, content: 'Ngày sinh không được trống' },
    erInvalidContactType: { code: 915, content: 'Loại liên hệ không hợp lệ' },

    erInvalidContractStatus: { code: 916, content: 'Trạng thái hợp đồng không hợp lệ' }
};
