import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "const.js";
import moment from "moment-timezone";
import DialogWrapper from "components/shared/DialogWrapper";

const deleteUserPermURL = cs.BaseURL + "/op-allocation/delete";
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class DeleteOPAllocation extends React.Component {
    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert("Wrong information !");
            return;
        }
        var perm_id = this.props.match.params.id;
        let queryString = `${deleteUserPermURL}`;

        fetch(queryString, {
            method: "POST",
            body: JSON.stringify({
                id: perm_id
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem(cs.System_Code + "-admin-token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("User Permission is deleted !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: "/op_allocation",
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Do you want to delete User Permission ?'} {...this.props}>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

DeleteOPAllocation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(DeleteOPAllocation));
