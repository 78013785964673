import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import cs from 'const.js';

const createUserURL = cs.BaseURL + '/user/create';
const rolesURL = cs.BaseURL + '/user-role/list';
const MerchantURL = cs.BaseURL + '/merchant/list';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class CreateUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            fullname: '',
            phone: '',
            code: '',
            role: cs.Role_OP_User,
            roles: [],
            merchant: [],
            codeMerchant: ''
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadRoles();
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.role) {
            window.alert('Please input information!');
            return;
        }
        if ((this.state.role === cs.Role_Merchant_Admin || this.state.role === cs.Role_Mc_Board_Of_Control_Member) && !this.state.codeMerchant) {
            window.alert('Admin must select merchant!');
            return;
        }
        let queryString = `${createUserURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name,
                fullname: this.state.fullname,
                phone: this.state.phone,
                code: this.state.code,
                role: this.state.role,
                mc_code: this.state.codeMerchant
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                 ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('User is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/user',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => { });
    };

    handleChange = (name) => (event) => {
        if (name === 'role') {
            if (event.target.value === cs.Role_Merchant_Admin || event.target.value === cs.Role_Mc_Board_Of_Control_Member || event.target.value === cs.Role_Telesale_Mc_Branch_Director) this.loadMerchant();
            else this.setState({ merchant: [] });
        }
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };

    isSysAdmin = () => {
        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-admin-user');
        if (user) username = JSON.parse(String(user)).name;
        return username == 'sysadmin';
    };

    loadRoles() {
        let queryString = `${rolesURL}?`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        roles: data.data
                    });
                } else {
                    this.setState({
                        roles: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => { });
    }

    loadMerchant() {
        let queryString = `${MerchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchant: data.data
                    });
                } else {
                    this.setState({
                        merchant: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchant: []
                });
            });
    }

    render() {
        const { classes } = this.props;
        
        return (
            <Dialog
                onClose={this.props.history.goBack}
                open={true}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="create_user_form-dialog-title" onClose={this.props.history.goBack}>
                    Create User
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="create_user_name"
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                        label="User Name"
                        type="name"
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="create_user_fullname"
                        value={this.state.fullname}
                        onChange={this.handleChange('fullname')}
                        label="User FullName"
                        type="name"
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="create_user_phone"
                        value={this.state.phone}
                        onChange={this.handleChange('phone')}
                        label="Phone Number"
                        type="number"
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="create_user_code"
                        value={this.state.code}
                        onChange={this.handleChange('code')}
                        label="Code"
                        type="text"
                    />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="create_user_role">Role</InputLabel>
                        <Select
                            value={this.state.role}
                            onChange={this.handleChange('role')}
                            inputProps={{
                                name: 'role',
                                id: 'create_user_role'
                            }}
                        >
                            {this.state.roles.map((pro) => (
                                <MenuItem value={pro.role_id}>
                                    {pro.role_id && <div>{pro.title}</div>}
                                    {!pro.role_id && <div>{pro.role_code}</div>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    {(this.state.role === cs.Role_Merchant_Admin || this.state.role === cs.Role_Mc_Board_Of_Control_Member || this.state.role === cs.Role_Telesale_Mc_Branch_Director) && (
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_user_merchant">Merchant</InputLabel>
                            <Select
                                value={this.state.codeMerchant}
                                onChange={this.handleChange('codeMerchant')}
                                inputProps={{
                                    name: 'codeMerchant',
                                    id: 'codeMerchant'
                                }}
                            >
                                <MenuItem value={null}>
                                    <em />
                                </MenuItem>
                                {this.state.merchant.map((pro, index) => (
                                    <MenuItem key={index} value={pro.code}>
                                        {pro.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.history.goBack} color="primary">
                        Cancel
                </Button>
                    <Button onClick={this.handleDialogAgree} color="primary">
                        Submit
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

CreateUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateUser));
