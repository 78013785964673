import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import { isAdmin } from 'service.js';
import moment from 'moment-timezone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogWrapper from 'components/shared/DialogWrapper';

const URL = cs.BaseURL + '/general/detail';
const updateUserURL = cs.BaseURL + '/general/update';
const MerchantURL = cs.BaseURL + '/merchant/list';
const UserRoleURL = cs.BaseURL + '/user-role/list';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

class EditUser extends React.Component {
    state = {
        user_roles: [
            {
                role_id: cs.Role_Telesale_OP_User,
                role_code: 'TelesaleOP - Nhân viên'
            },
            {
                role_id: cs.Role_Telesale_OP_Leader,
                role_code: 'TelesaleOP - Trưởng phòng'
            },
            {
                role_id: cs.Role_Telesale_OP_Manager,
                role_code: 'TelesaleOP - Quản lý'
            },
            {
                role_id: cs.Role_Telesale_Merchant_User,
                role_code: 'TelesaleMerchant - Nhân viên'
            },
            {
                role_id: cs.Role_Telesale_Merchant_Leader,
                role_code: 'TelesaleMerchant - Trưởng phòng'
            },
            {
                role_id: cs.Role_Telesale_Merchant_Manager,
                role_code: 'TelesaleMerchant - Quản lý'
            },
            {
                role_id: cs.Role_Marketing_OP_User,
                role_code: 'MarketingOP - Nhân viên'
            },
            {
                role_id: cs.Role_Marketing_OP_Leader,
                role_code: 'MarketingOP - Trưởng phòng'
            },
            {
                role_id: cs.Role_Marketing_OP_Manager,
                role_code: 'MarketingOP - Quản lý'
            },
            {
                role_id: cs.Role_Marketing_Merchant_User,
                role_code: 'MarketingMerchant - Nhân viên'
            },
            {
                role_id: cs.Role_Marketing_Merchant_Leader,
                role_code: 'MarketingMerchant - Trưởng phòng'
            },
            {
                role_id: cs.Role_Marketing_Merchant_Manager,
                role_code: 'MarketingMerchant - Quản lý'
            }
        ],
        id: this.props.match.params.id,
        name: '',
        phone: '',
        fullname: '',
        code: '',
        role: '',
        codeMerchant: '',

        locked: false,
        order_createable: true,
        order_importable: false,
        order_approvable: true,
        order_cancelable: true,
        new_password: '',
        roles: [],
        merchant: []
    };
    constructor(props) {
        super(props);

        this.loadData();
        !isAdmin() && this.loadUserRole();
        isAdmin() && this.loadMerchant();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?new=true&id=${id}`;
        return queryString;
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState(
                        {
                            name: data.data.name,
                            fullname: data.data.fullname,
                            phone: data.data.phone,
                            code: data.data.code,
                            role: data.data.role,
                            codeMerchant: data.data.mc_code,

                            locked: data.data.locked,
                            order_createable: data.data.order_createable,
                            order_importable: data.data.order_importable,
                            order_approvable: data.data.order_approvable,
                            order_cancelable: data.data.order_cancelable,
                            loading: false
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    loadMerchant() {
        let queryString = `${MerchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchant: data.data
                    });
                    console.log(data.data);
                } else {
                    this.setState({
                        merchant: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchant: []
                });
            });
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.role) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${updateUserURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name,
                fullname: this.state.fullname,
                phone: this.state.phone,
                code: this.state.code,
                role: this.state.role,
                mc_code: this.state.codeMerchant,

                locked: this.state.locked ? 1 : 0,
                order_createable: this.state.order_createable ? 1 : 0,
                order_importable: this.state.order_importable ? 1 : 0,
                order_approvable: this.state.order_approvable ? 1 : 0,
                order_cancelable: this.state.order_cancelable ? 1 : 0
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('User is updated !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/general',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => { });
    };

    handleChange = (name) => (event) => {
        if (name == 'locked') this.setState({ locked: event.target.checked });
        else if (name == 'order_createable')
            this.setState({ order_createable: event.target.checked });
        else if (name == 'order_importable')
            this.setState({ order_importable: event.target.checked });
        else if (name == 'order_approvable')
            this.setState({ order_approvable: event.target.checked });
        else if (name == 'order_cancelable')
            this.setState({ order_cancelable: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => { }
            );
    };

    loadUserRole() {
        fetch(UserRoleURL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        user_roles: data.data
                    });
                } else {
                    this.setState({
                        user_roles: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    user_roles: []
                });
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });
    }

    render() {
        const { classes } = this.props;
        const { user } = this.state;
        return (
            <>
                <DialogWrapper title={'Update User'} {...this.props}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            disabled
                            margin="dense"
                            id="edit_user_name"
                            required={true}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            label="User Name"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="edit_user_fullname"
                            required={true}
                            value={this.state.fullname}
                            onChange={this.handleChange('fullname')}
                            label="User FullName"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_phone"
                            required={true}
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            label="Phone Number"
                            type="number"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_code"
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            label="Code"
                            type="text"
                        />
                        <FormControl fullWidth>
                            <InputLabel htmlFor="edit_user_role">Role</InputLabel>
                            <Select
                                value={this.state.role}
                                onChange={this.handleChange('role')}
                                inputProps={{
                                    name: 'role',
                                    id: 'edit_user_role'
                                }}
                            >
                                <MenuItem value={null}>
                                    <em />
                                </MenuItem>
                                {this.state.user_roles.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.role_id}>
                                            {item.role_code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {isAdmin() ? (
                            <>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_user_merchant">Merchant</InputLabel>
                                    <Select
                                        value={this.state.codeMerchant}
                                        onChange={this.handleChange('codeMerchant')}
                                        inputProps={{
                                            name: 'codeMerchant',
                                            id: 'codeMerchant'
                                        }}
                                    >
                                        <MenuItem value={null}>
                                            All
                                    </MenuItem>
                                        {this.state.merchant.map((pro) => (
                                            <MenuItem value={pro.code}>{pro.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <FormControl fullWidth disabled>
                                <InputLabel htmlFor="create_user_merchant">
                                    {this.state.codeMerchant}
                                </InputLabel>
                                <Select>
                                    <MenuItem value={null}></MenuItem>
                                </Select>
                            </FormControl>
                        )}

                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.locked}
                                value={this.state.locked}
                                onChange={this.handleChange('locked')}
                                color="primary"
                            />
                            {this.state.locked ? 'Locked' : 'Unlocked'}
                        </div>
                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.order_createable}
                                value={this.state.order_createable}
                                onChange={this.handleChange('order_createable')}
                                color="primary"
                            />
                            {this.state.order_createable
                                ? 'Can Create Order'
                                : 'Can not Create Order'}
                        </div>
                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.order_importable}
                                value={this.state.order_importable}
                                onChange={this.handleChange('order_importable')}
                                color="primary"
                            />
                            {this.state.order_importable
                                ? 'Can Import Orders'
                                : 'Can not Import Orders'}
                        </div>
                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.order_approvable}
                                value={this.state.order_approvable}
                                onChange={this.handleChange('order_approvable')}
                                color="primary"
                            />
                            {this.state.order_approvable
                                ? 'Can Approve Order'
                                : 'Can not Approve Order'}
                        </div>
                        <div fullWidth className={classes.create_button}>
                            <Switch
                                checked={this.state.order_cancelable}
                                value={this.state.order_cancelable}
                                onChange={this.handleChange('order_cancelable')}
                                color="primary"
                            />
                            {this.state.order_cancelable
                                ? 'Can Cancel Order'
                                : 'Can not Cancel Order'}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </>
        );
    }
}

EditUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(EditUser));
