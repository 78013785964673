import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import moment from 'moment-timezone';
import { DropzoneArea } from 'material-ui-dropzone';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import DialogWrapper from 'components/shared/DialogWrapper';

const createMerchantURL = cs.BaseURL + '/merchant/create';
const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';
const styles = theme => ({});

class CreateMerchant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            code: '',

            contact_person_name: '',
            contact_person_DOB: '',
            contact_person_gender: false,
            contact_person_position: '',
            contact_commune: '',
            contact_district: '',
            contact_province: '',
            contact_email: '',
            contact_telephone: '',
            contact_id_number: '',
            contact_id_issue_date: '',
            contact_id_issue_place: '',

            merchant_business_place: '',
            merchant_current_address: '',
            merchant_email: '',
            merchant_industry: '',
            merchant_product_list: '',
            merchant_registration_number: '',
            merchant_tax_number: '',
            merchant_telephone: '',

            file: null,

            states: [],
            districts: [],
            wards: [],
        };
        this.loadStates = this.loadStates.bind(this);
        this.loadDistricts = this.loadDistricts.bind(this);
        this.loadWards = this.loadWards.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        this.loadStates();
    }

    loadStates() {
        let queryString = `${statesURL}`;
        // var lastData = JSON.parse(localStorage.getItem(cs.System_Code + '-admin-states'));
        // if (
        //     lastData &&
        //     queryString === lastData.query &&
        //     parseInt(lastData.query_at) + 60 * 60 * 24 * 30 > moment().unix()
        // ) {
        //     this.setState({ states: lastData.states });
        //     return;
        // }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("STATE:", data);
                if (data && data.data) {
                    this.setState({
                        states: data.data
                    });

                    localStorage.setItem(
                        cs.System_Code + '-admin-states',
                        JSON.stringify({
                            states: data.data,
                            query: queryString,
                            query_at: moment().unix()
                        })
                    );
                } else {
                    this.setState({
                        states: []
                    });
                    localStorage.setItem(cs.System_Code + '-admin-states', null);
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                localStorage.setItem(cs.System_Code + '-admin-states', null);
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        this.setState({
            contact_district: '',
            contact_commune: '',
        })
        let queryString = `${districtsURL}?state_code=${this.state.contact_province}`;
        if (queryString === this.lastQueryDistrict) {
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        districts: data.data
                    });
                    this.lastQueryDistrict = queryString;
                } else {
                    this.setState({
                        districts: []
                    });
                    this.lastQueryDistrict = null;
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.lastQueryDistrict = null;
            });
    }

    loadWards() {
        this.setState({
            //contact_district:'',
            contact_commune: '',
        })
        let queryString = `${wardsURL}?district_code=${this.state.contact_district}`;
        if (queryString === this.lastQueryWard) {
            return;
        }

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        wards: data.data
                    });
                    this.lastQueryWard = queryString;
                } else {
                    this.setState({
                        wards: []
                    });
                    this.lastQueryWard = null;
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.lastQueryWard = null;
            });
    }

    handleDialogAgree = () => {
        console.log(this.state);
        if (!this.state.name || !this.state.code || !this.state.file
            || !this.state.contact_province || !this.state.contact_district
            || !this.state.contact_commune || !this.state.contact_person_name
            || !this.state.contact_person_DOB || !this.state.contact_person_gender
            || !this.state.contact_person_position || !this.state.contact_email
            || !this.state.contact_telephone || !this.state.contact_id_number
            || !this.state.contact_id_issue_date || !this.state.contact_id_issue_place
        ) {
            window.alert('Please input information !');
            return;
        }

        let state = this.state.states.filter((el) => el.state_code === this.state.contact_province)
        let district = this.state.districts.filter((el) => el.district_code === this.state.contact_district)
        let ward = this.state.wards.filter((el) => el.ward_code === this.state.contact_commune)
        console.log(state);
        console.log(district);
        console.log(ward);
        let queryString = `${createMerchantURL}`;
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('code', this.state.code);
        formData.append('img_logo', this.state.file);

        formData.append('contact_person_name', this.state.contact_person_name);
        formData.append('contact_person_DOB', moment(this.state.contact_person_DOB).unix());
        formData.append('contact_person_gender', this.state.contact_person_gender ? 1 : 0);
        formData.append('contact_person_position', this.state.contact_person_position);
        formData.append('contact_commune', ward[0].ward_name);
        formData.append('contact_district', district[0].district_name);
        formData.append('contact_province', state[0].state_name);
        formData.append('contact_email', this.state.contact_email);
        formData.append('contact_telephone', this.state.contact_telephone);
        formData.append('contact_id_number', this.state.contact_id_number);
        formData.append('contact_id_issue_date', moment(this.state.contact_id_issue_date).unix());
        formData.append('contact_id_issue_place', this.state.contact_id_issue_place);

        formData.append('merchant_business_place', this.state.merchant_business_place);
        formData.append('merchant_current_address', this.state.merchant_current_address);
        formData.append('merchant_email', this.state.merchant_email);
        formData.append('merchant_industry', this.state.merchant_industry);
        formData.append('merchant_product_list', this.state.merchant_product_list);
        formData.append('merchant_registration_number', this.state.merchant_registration_number);
        formData.append('merchant_tax_number', this.state.merchant_tax_number);
        formData.append('merchant_telephone', this.state.merchant_telephone);

        console.log(formData);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-admin-token') }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Merchant is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/merchant',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        if (name == 'contact_person_gender') this.setState({ contact_person_gender: event.target.checked });
        else {
            this.setState({

                [name]: event.target.value
            }, () => {
                if (name == 'contact_province') this.loadDistricts();
                if (name == 'contact_district') this.loadWards();
                if (name == 'contact_commune') console.log(event.target.value);
            });
        }

    };

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Create Merchant'} {...this.props}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_merchant_name"
                            required={true}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            label="Merchant Name (*)"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_merchant_code"
                            required={true}
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            label="Merchant Code (*)"
                            type="code"
                        />

                        <Divider />
                        <Grid item xs={7}>
                            <Typography variant="body1">Contact (*):</Typography>
                        </Grid>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="contact_person_name"
                            required={true}
                            value={this.state.contact_person_name}
                            onChange={this.handleChange('contact_person_name')}
                            label="contact_person_name"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="contact_person_position"
                            required={true}
                            value={this.state.contact_person_position}
                            onChange={this.handleChange('contact_person_position')}
                            label="contact_person_position (*)"
                            type="code"
                        />

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >



                            <Grid item xs={6}>
                                <div fullWidth className={classes.gender_button}>
                                    Giới tính (*)
                                    <Switch
                                        //checked={this.state.lead_gender}
                                        value={this.state.contact_person_gender}
                                        onChange={this.handleChange('contact_person_gender')}
                                        color="primary"
                                    />
                                    {this.state.contact_person_gender ? 'Nam' : 'Nữ'}
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="contact_person_DOB"
                                    label="Ngày sinh (*)"
                                    fullWidth
                                    value={this.state.contact_person_DOB}
                                    //value={"2017-05-24"}
                                    onChange={this.handleChange('contact_person_DOB')}
                                    type="date"
                                    InputProps={{
                                        step: "1"
                                    }}
                                    InputLabelProps={{
                                        shrink: true,

                                    }}
                                />
                            </Grid>
                        </Grid>


                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="contact_email"
                                    required={true}
                                    value={this.state.contact_email}
                                    onChange={this.handleChange('contact_email')}
                                    label="contact_email (*)"
                                    type="code"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="contact_telephone"
                                    required={true}
                                    value={this.state.contact_telephone}
                                    onChange={this.handleChange('contact_telephone')}
                                    label="contact_telephone (*)"
                                    type="code"
                                />
                            </Grid>
                        </Grid>



                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="contact_province">
                                        Tỉnh/thành phố (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.contact_province}
                                        onChange={this.handleChange('contact_province')}
                                        inputProps={{
                                            name: 'cus_adr_state_code',
                                            id: 'cus_adr_state_id'
                                        }}
                                    >
                                        {this.state.states.map(pro => (
                                            <MenuItem value={pro.state_code}>
                                                {pro.state_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="contact_district">
                                        Quận / huyện (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.contact_district}
                                        onChange={this.handleChange('contact_district')}
                                        inputProps={{
                                            name: 'adr_district_code',
                                            id: 'adr_district_id'
                                        }}
                                    >
                                        {this.state.districts.map(pro => (
                                            <MenuItem value={pro.district_code}>
                                                {pro.district_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="adr_ward_id">Xã/Phường (*)</InputLabel>
                                    <Select
                                        value={this.state.contact_commune}
                                        onChange={this.handleChange('contact_commune')}
                                        inputProps={{
                                            name: 'adr_ward_code',
                                            id: 'adr_ward_id'
                                        }}
                                    >
                                        {this.state.wards.map(pro => (
                                            <MenuItem value={pro.ward_code}>
                                                {pro.ward_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="contact_email"
                            required={true}
                            value={this.state.contact_email}
                            onChange={this.handleChange('contact_email')}
                            label="contact_email"
                            type="code"
                        /> */}



                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="contact_id_number"
                            required={true}
                            value={this.state.contact_id_number}
                            onChange={this.handleChange('contact_id_number')}
                            label="contact_id_number (*)"
                            type="code"
                        />

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    id="contact_id_issue_date"
                                    label="Ngày cấp (*)"
                                    fullWidth
                                    value={this.state.contact_id_issue_date}
                                    //value={"2017-05-24"}
                                    onChange={this.handleChange('contact_id_issue_date')}
                                    type="date"
                                    InputProps={{
                                        step: "1"
                                    }}
                                    InputLabelProps={{
                                        shrink: true,

                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="Nơi cấp (*)"
                                    required={true}
                                    value={this.state.contact_id_issue_place}
                                    onChange={this.handleChange('contact_id_issue_place')}
                                    label="contact_id_issue_place"
                                    type="code"
                                />
                            </Grid>
                        </Grid>



                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_business_place"
                            required={true}
                            value={this.state.merchant_business_place}
                            onChange={this.handleChange('merchant_business_place')}
                            label="merchant_business_place"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_current_address"
                            required={true}
                            value={this.state.merchant_current_address}
                            onChange={this.handleChange('merchant_current_address')}
                            label="merchant_current_address"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_email"
                            required={true}
                            value={this.state.merchant_email}
                            onChange={this.handleChange('merchant_email')}
                            label="merchant_email"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_industry"
                            required={true}
                            value={this.state.merchant_industry}
                            onChange={this.handleChange('merchant_industry')}
                            label="merchant_industry"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_product_list"
                            required={true}
                            value={this.state.merchant_product_list}
                            onChange={this.handleChange('merchant_product_list')}
                            label="merchant_product_list"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_registration_number"
                            required={true}
                            value={this.state.merchant_registration_number}
                            onChange={this.handleChange('merchant_registration_number')}
                            label="merchant_registration_number"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_tax_number"
                            required={true}
                            value={this.state.merchant_tax_number}
                            onChange={this.handleChange('merchant_tax_number')}
                            label="merchant_tax_number"
                            type="code"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="merchant_telephone"
                            required={true}
                            value={this.state.merchant_telephone}
                            onChange={this.handleChange('merchant_telephone')}
                            label="merchant_telephone"
                            type="code"
                        />

                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.gridContainer}
                        >
                            <Grid item gutterBottom>
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    dropzoneText={
                                        this.state.file && this.state.file.name
                                            ? this.state.file.name
                                            : 'Upload file ảnh logo'
                                    }
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviewsInDropzone={false}
                                    onChange={this.handleDropZoneChange}
                                    dropZoneClass={classes.dropZone}
                                />
                            </Grid>
                            <Grid item>
                                <div></div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Create New
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

CreateMerchant.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateMerchant));
