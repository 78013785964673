import React from 'react';
import { BrowserRouter as Router, withRouter, Route, Link } from 'react-router-dom';
import {
    Table
} from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { getRole, isAdmin } from 'service';
import DataTable from 'components/shared/DataTable';

import cs from 'const.js';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ResetPassword from './ResetPassword';

const Admin_MC_URL = cs.BaseURL + '/user/list';
const Admin_URL = cs.BaseURL + '/general/list';
const MerchantURL = cs.BaseURL + '/merchant/list';
const UserRoleURL = cs.BaseURL + '/user-role/list';

function formatValue(value) {
    if (value && value.adr_district) {
        value.adr_district = value.adr_district.replace('Thành phố', 'TP').trim();
        value.adr_district = value.adr_district.replace('Huyện', '').trim();
    }

    if (value && value.adr_state) {
        value.adr_state = value.adr_state.replace('Thành phố', 'TP').trim();
        value.adr_state = value.adr_state.replace('Tỉnh', '').trim();
    }

    if (value && value.role) {
        if (value.role === cs.Role_Telesale_OP_User) value.role = 'TelesaleOP - Nhân viên';
        if (value.role === cs.Role_Telesale_OP_Leader) value.role = 'TelesaleOP - Trưởng phòng';
        if (value.role === cs.Role_Telesale_OP_Manager) value.role = 'TelesaleOP - Quản lý';

        if (value.role === cs.Role_Telesale_Merchant_User)
            value.role = 'TelesaleMerchant - Nhân viên';
        if (value.role === cs.Role_Telesale_Merchant_Leader)
            value.role = 'TelesaleMerchant - Trưởng phòng';
        if (value.role === cs.Role_Telesale_Merchant_Manager)
            value.role = 'TelesaleMerchant - Quản lý';

        if (value.role === cs.Role_Marketing_OP_User) value.role = 'MarketingOP - Nhân viên';
        if (value.role === cs.Role_Marketing_OP_Leader) value.role = 'MarketingOP - Trưởng phòng';
        if (value.role === cs.Role_Marketing_OP_Manager) value.role = 'MarketingOP - Quản lý';

        if (value.role === cs.Role_Marketing_Merchant_User)
            value.role = 'MarketingMerchant - Nhân viên';
        if (value.role === cs.Role_Marketing_Merchant_Leader)
            value.role = 'MarketingMerchant - Trưởng phòng';
        if (value.role === cs.Role_Marketing_Merchant_Manager)
            value.role = 'MarketingMerchant - Quản lý';
        if (value.role === cs.Role_Mc_Board_Of_Control_Member)
            value.role = 'Thành viên ban kiểm soát đối tác';
    }

    if (value && value.locked) value.locked = 'Đã khóa';
    else value.locked = '';

    return value;
}

class UserGeneralList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            role_list: [
                { role_id: cs.Role_Telesale_OP_User, title: 'TelesaleOP - Nhân viên' },
                { role_id: cs.Role_Telesale_OP_Leader, title: 'TelesaleOP - Trưởng phòng' },
                { role_id: cs.Role_Telesale_OP_Manager, title: 'TelesaleOP - Quản lý' },
                { role_id: cs.Role_Telesale_Merchant_User, title: 'TelesaleMerchant - Nhân viên' },
                {
                    role_id: cs.Role_Telesale_Merchant_Leader,
                    title: 'TelesaleMerchant - Trưởng phòng'
                },
                { role_id: cs.Role_Telesale_Merchant_Manager, title: 'TelesaleMerchant - Quản lý' },
                { role_id: cs.Role_Marketing_OP_User, title: 'MarketingOP - Nhân viên' },
                { role_id: cs.Role_Marketing_OP_Leader, title: 'MarketingOP - Trưởng phòng' },
                { role_id: cs.Role_Marketing_OP_Manager, title: 'MarketingOP - Quản lý' },
                {
                    role_id: cs.Role_Marketing_Merchant_User,
                    title: 'MarketingMerchant - Nhân viên'
                },
                {
                    role_id: cs.Role_Marketing_Merchant_Leader,
                    title: 'MarketingMerchant - Trưởng phòng'
                },
                {
                    role_id: cs.Role_Marketing_Merchant_Manager,
                    title: 'MarketingMerchant - Quản lý'
                }
            ],
            columns: [
                { name: 'name', title: 'Name' },
                { name: 'fullname', title: 'FullName' },
                { name: 'code', title: 'Code' },
                { name: 'phone', title: 'Phone' },
                { name: 'type', title: 'Type' },
                { name: 'role', title: 'Role' },
                { name: 'mc_code', title: 'Merchant' },
                // { name: 'adr_state', title: 'Adr State' },
                // { name: 'adr_district', title: 'Adr District' },
                // { name: 'adr_ward', title: 'Adr Ward' },
                { name: 'locked', title: ' ' },
                { name: 'action', title: 'Options' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'name', align: 'left', width: 350 },
                { columnName: 'fullname', align: 'left', width: 230 },
                { columnName: 'code', align: 'center', width: 150 },
                { columnName: 'phone', align: 'center', width: 200 },
                { columnName: 'type', align: 'left', width: 200 },
                { columnName: 'role', align: 'left', width: 300 },
                { columnName: 'mc_code', align: 'left', width: 150 },
                // { columnName: 'adr_state', align: 'center', width: 100 },
                // { columnName: 'adr_district', align: 'center', width: 100 },
                // { columnName: 'adr_ward', align: 'center', width: 100 },
                { columnName: 'locked', align: 'center', width: 80 },
                { columnName: 'action', align: 'center', width: 150 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'id', direction: 'desc' }],
            merchant: [],
            searchMerchant: '',
            showImportManager: false,
            showImportLeader: false
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);

        if (isAdmin()) this.loadMerchant();
        else {
            this.loadUserRole();
        }
    }

    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            searchName,
            searchFullName,
            searchRole,
            searchMerchant
        } = this.state;

        const path = isAdmin() ? Admin_URL : Admin_MC_URL;

        let queryString = `${path}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;
        if (searchName) queryString = `${queryString}&name=${searchName}`;
        if (searchFullName) queryString = `${queryString}&fullname=${searchFullName}`;
        if (searchRole) queryString = `${queryString}&role=${searchRole}`;
        if (searchMerchant) queryString = `${queryString}&mc_code=${searchMerchant}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    loadMerchant() {
        let queryString = `${MerchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchant: data.data
                    });
                } else {
                    this.setState({
                        merchant: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchant: []
                });
            });
    }

    loadUserRole() {
        fetch(UserRoleURL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        role_list: data.data
                    });
                } else {
                    this.setState({
                        role_list: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    user_roles: []
                });
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });
    }

    handleChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                if (name == 'searchType')
                    this.setState({ searchRole: null, currentPage: 0 }, () => {
                        this.loadData();
                    });
                else
                    this.setState({ currentPage: 0 }, () => {
                        this.loadData();
                    });
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.props.history.location.state.reload = false;
            this.loadData();
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New User"
                    component={Link}
                    to={'/general/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Create New User</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import Telesale Manager"
                    component={Link}
                    to={'/import/manager'}
                >
                    <Icon>cloud_upload</Icon>
                    <span style={{ marginLeft: 10 }}>Import Telesales Giám đốc</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import Telesale Leader"
                    component={Link}
                    to={'/import/leader'}
                >
                    <Icon>cloud_upload</Icon>
                    <span style={{ marginLeft: 10 }}>Import Telesales Trưởng phòng</span>
                </Button>{' '}
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        className={classes.search_heading}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Search ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="user_name"
                                label="User"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchName}
                                onChange={this.handleChange('searchName')}
                                margin="normal"
                            />

                            <TextField
                                id="user_fullname"
                                label="User Name"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchFullName}
                                onChange={this.handleChange('searchFullName')}
                                margin="normal"
                            />

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="searchRole">Role</InputLabel>
                                <Select
                                    value={this.state.searchRole}
                                    onChange={this.handleChange('searchRole')}
                                    inputProps={{
                                        name: 'searchRole',
                                        id: 'searchRole'
                                    }}
                                >
                                    <MenuItem value={null}>
                                        All
                                    </MenuItem>
                                    {this.state.role_list.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.role_id}>
                                                {item.title}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            {isAdmin() && (
                                <FormControl className={classes.search_field}>
                                    <InputLabel htmlFor="searchMerchant">Merchant</InputLabel>
                                    <Select
                                        value={this.state.searchMerchant}
                                        onChange={this.handleChange('searchMerchant')}
                                        inputProps={{
                                            name: 'searchMerchant',
                                            id: 'searchMerchant'
                                        }}
                                    >
                                        <MenuItem value={null}>
                                        All
                                    </MenuItem>
                                        {this.state.merchant.map((pro) => (
                                            <MenuItem value={pro.code}>{pro.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <DataTable
                    rows={rows}
                    columns={columns}
                    columnWidths={tableColumnExtensions}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    loading={loading}
                    sorting={sorting}
                    totalCount={totalCount}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                    changeSorting={this.changeSorting}
                    CellComponent={CellComponent}
                />
                <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
                    <Route exact path="/general/create" component={CreateUser} />
                    <Route exact path="/general/edit/:id" component={EditUser} />
                    <Route exact path="/general/delete/:id" component={DeleteUser} />
                    <Route exact path="/general/reset_pass/:id" component={ResetPassword} />
                </ToastProvider>
            </div>
        );
    }
}

UserGeneralList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = (theme) => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    search_select_menu: {
        width: 400
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 300,
        minWidth: 120
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    cell_button: {
        width: 100,
        marginLeft: 2,
        marginRight: 2
    }
});

class ActionCell extends React.Component {
    isSysAdmin = () => {
        var username = this.props.row.name;
        return username == 'sysadmin';
    };

    render() {
        return (
            <Table.Cell
                style={{
                    padding: 2,
                    justifyContent: 'center'
                }}
            >
                <span>
                    <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        component={Link}
                        to={'/general/reset_pass/' + this.props.row.id}
                    >
                        <Icon>security</Icon>
                    </Button>

                    {!this.isSysAdmin() && (
                        <Button
                            color="primary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/general/edit/' + this.props.row.id}
                        >
                            <Icon>edit</Icon>
                        </Button>
                    )}

                    {!this.isSysAdmin() && (
                        <Button
                            color="secondary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/general/delete/' + this.props.row.id}
                        >
                            <Icon>remove_circle</Icon>
                        </Button>
                    )}
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = (props) => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                margin: 1,
                padding: 1,
                paddingLeft: 40
            }}
        />
    );
};

export default withRouter(withStyles(styles)(UserGeneralList));
