import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ToastProvider } from 'react-toast-notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import Collapse from '@material-ui/core/Collapse';
import BackupIcon from '@material-ui/icons/Backup';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import cs from '../const.js';
import { isAdmin } from '../service';

const revokeTokenURL = cs.BaseURL + '/auth/logout';
const drawerWidth = 240;

const styles = (theme) => ({
    root: {
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    toolbarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 2px',
        ...theme.mixins.toolbar
    },
    username: {
        overflow: 'hidden',
        marginRight: 20
    },
    avatar: {
        marginRight: 20
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        maxHeight: '100vh',
        overflow: 'scroll',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        height: '100vh',
        overflow: 'auto'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            collapse_open: false
        };
    }

    handleLogOut = () => {
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleCollapse = () => {
        this.setState({ collapse_open: !this.state.collapse_open });
    }

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-admin-user');
        if (user) username = JSON.parse(String(user)).name;

        const mainListItems = (
            <div>
                {isAdmin() && (
                    <ListItem button component={Link} to="/user">
                        <ListItemIcon>
                            <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="DSA User" />
                    </ListItem>
                )}
                <ListItem button component={Link} to="/general">
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    {isAdmin() ? (
                        <ListItemText primary="General User" />
                    ) : (
                        <ListItemText primary="User" />
                    )}
                </ListItem>
                {isAdmin() && (
                    <>
                        <ListItem button component={Link} to="/user_perm">
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="User Permission" />
                        </ListItem>

                        <ListItem button component={Link} to="/op_allocation">
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="OP Allocation" />
                        </ListItem>
                    </>
                )}

                <Divider variant="middle" />
                {isAdmin() && (
                    <ListItem button component={Link} to="/merchant">
                        <ListItemIcon>
                            <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Merchant" />
                    </ListItem>
                )}
                <ListItem button component={Link} to="/product">
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product" />
                </ListItem>
                <Divider variant="middle" />

                <ListItem button component={Link} to="/telesale">
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Telesale" />
                </ListItem>

                <ListItem button onClick={this.handleCollapse}>
                    <ListItemIcon>
                        <BackupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Import" />
                    {this.state.collapse_open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.collapse_open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="/import/manager">
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Director" />
                        </ListItem>
                        <ListItem button component={Link} to="/import/leader">
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Department Head" />
                        </ListItem>
                        <ListItem button component={Link} to="/import/telesale">
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Telesale" />
                        </ListItem>
                        {isAdmin() && (
                            <ListItem button component={Link} to="/import/merchant">
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Merchant" />
                            </ListItem>
                        )}
                        <ListItem button component={Link} to="/import/product">
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Product" />
                        </ListItem>
                    </List>
                </Collapse>

            </div>
        );
        return (
            <>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                >
                    <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(
                                classes.menuButton,
                                this.state.open && classes.menuButtonHidden
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" color="inherit" noWrap className={classes.title}>
                            Administrator {!isAdmin() && 'Merchant'} Center
                            </Typography>

                        <Button
                            color="inherit"
                            iconStyle={{
                                height: 200,
                                width: 200,
                                fontSize: '48px'
                            }}
                            aria-label="Change Password"
                            onClick={this.handleShowChangePassword}
                        >
                            <Icon>vpn_key</Icon>
                            <span style={{ marginLeft: 10 }}>Change Password</span>
                        </Button>

                        <Button
                            color="inherit"
                            iconStyle={{
                                height: 200,
                                width: 200,
                                fontSize: '48px'
                            }}
                            aria-label="Sign Out"
                            onClick={this.handleLogOut}
                        >
                            <Icon>exit_to_app</Icon>
                            <span style={{ marginLeft: 10 }}>Sign Out</span>
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !this.state.open && classes.drawerPaperClose
                        )
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbarHeader}>
                        {cs.System_Id === 1 ? (
                            <Avatar
                                className={classes.avatar}
                                src={require('../img/logo-app-trans.png')}
                            />
                        ) : (
                            <Avatar
                                className={classes.avatar}
                                src={require('../img/logo-small.png')}
                            />
                        )}
                        <Typography variant="h6" className={classes.username}>
                            {username}
                        </Typography>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{mainListItems}</List>
                </Drawer>
            </>
        );
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navbar);
