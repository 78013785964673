import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';

import ChangePassword from 'components/auth/ChangePassword';
import cs from 'const';
import { isAdmin } from 'service';
import AppRoute from 'components/AppRoute';

import Navbar from 'components/Navbar';

const styles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em',
            backgroundColor: '#F5F5F5'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: 'rgba(0,0,0,.1)'
        }
    },
    root: {
        height: '100vh',
        maxHeight: '100vh',
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        overflow: 'auto'
    },
    tableContainer: {
        height: '100vh'
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateOrder: false,
            showChangePassword: false
        };
    }

    handleShowChangePassword = () => {
        this.setState({ showChangePassword: true });
    };

    handleCloseChangePassword = () => {
        this.setState({ showChangePassword: false });
    };

    render() {
        const { classes } = this.props;

        return (
            <Router>
                <div className={classes.root}>
                    <Navbar />
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        
                        <AppRoute />

                        <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
                            <ChangePassword
                                open={this.state.showChangePassword}
                                onClose={this.handleCloseChangePassword}
                            />
                        </ToastProvider>
                    </main>
                </div>
            </Router>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
