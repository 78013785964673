import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from 'const.js';
import moment from 'moment-timezone';
import DialogWrapper from 'components/shared/DialogWrapper';

const URL = cs.BaseURL + '/general/reset_pwd';
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            new_password: '',
            new_password_retry: ''
        };
    }

    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert('Wrong information !');
            return;
        }

        var user_id = this.props.match.params.id;
        let queryString = `${URL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: user_id,
                password: this.state.new_password
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Password has been changed !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/general',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };
    handleFieldChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };
    render() {
        const { classes } = this.props;

        const isMatched = this.state.new_password != this.state.new_password_retry && this.state.new_password_retry != '';

        return (
            <div>
                <DialogWrapper title={'Do you want to reset password ?'} {...this.props}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="product_code"
                            label="New password"
                            value={this.state.new_password}
                            onChange={this.handleFieldChange('new_password')}
                            margin="normal"
                            required={true}
                            type="password"
                        />

                        <TextField
                            fullWidth
                            id="product_code"
                            label="Input again"
                            value={this.state.new_password_retry}
                            onChange={this.handleFieldChange('new_password_retry')}
                            margin="normal"
                            required={true}
                            type="password"
                            error={isMatched}
                            helperText={isMatched ? 'Not matched !' : ' '}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary" autoFocus>
                            Reset
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(ResetPassword));
