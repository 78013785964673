import React from 'react';
import { BrowserRouter as Router, withRouter, Route, Link } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';

import cs from 'const.js';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ResetPassword from './ResetPassword';

const URL = cs.BaseURL + '/user/list';
const rolesURL = cs.BaseURL + '/user-role/list';

function formatValue(value) {
    if (value && value.adr_district) {
        value.adr_district = value.adr_district.replace('Thành phố', 'TP').trim();
        value.adr_district = value.adr_district.replace('Huyện', '').trim();
    }

    if (value && value.adr_state) {
        value.adr_state = value.adr_state.replace('Thành phố', 'TP').trim();
        value.adr_state = value.adr_state.replace('Tỉnh', '').trim();
    }

    // if (value && value.role) {
    //     if (value.role == cs.Role_Sys_Admin) value.role = 'System';
    //     if (value.role == cs.Role_Admin) value.role = 'Quản trị hệ thống';
    //     if (value.role == cs.Role_OP_User) value.role = 'Vận hành - Nhân viên';
    //     if (value.role == cs.Role_OP_Leader) value.role = 'Vận hành - Trưởng phòng';
    //     if (value.role == cs.Role_OP_Manager) value.role = 'Vận hành - Quản lý';


    //     if (value.role == cs.Role_Merchant_User) value.role = 'Đối tác - Nhân viên';
    //     if (value.role == cs.Role_Merchant_Leader) value.role = 'Đối tác - Trưởng phòng';
    //     if (value.role == cs.Role_Merchant_Manager) value.role = 'Đối tác - Quản lý';

    //     if (value.role == cs.Role_HR_User) value.role = 'Nhân sự - Nhân viên';
    //     if (value.role == cs.Role_HR_Leader) value.role = 'Nhân sự - Trưởng phòng';
    //     if (value.role == cs.Role_HR_Manager) value.role = 'Nhân sự - Quản lý';

    //     if (value.role == cs.Role_News_User) value.role = 'Truyền thông - Nhân viên';
    //     if (value.role == cs.Role_News_Leader) value.role = 'Truyền thông - Trưởng phòng';

    //     if (value.role == cs.Role_Document_HubUser) value.role = 'Quản lý hồ sơ tại Hub - Nhân viên';
    //     if (value.role == cs.Role_Document_HubLeader) value.role = 'Quản lý hồ sơ tại Hub - Trưởng phòng';
    //     if (value.role == cs.Role_Document_HubManager) value.role = 'Quản lý hồ sơ tại Hub - Quản lý';

    //     if (value.role == cs.Role_Document_BankUser) value.role = 'Quản lý hồ sơ tại Bank - Nhân viên';
    //     if (value.role == cs.Role_Document_BankLeader) value.role = 'Quản lý hồ sơ tại Bank - Trưởng phòng';
    //     if (value.role == cs.Role_Document_BankManager) value.role = 'Quản lý hồ sơ tại Bank - Quản lý';

    //     if (value.role == cs.Role_Card_HubUser) value.role = 'Quản lý thẻ tại Hub - Nhân viên';
    //     if (value.role == cs.Role_Card_HubLeader) value.role = 'Quản lý thẻ tại Hub - Trưởng phòng';
    //     if (value.role == cs.Role_Card_HubManager) value.role = 'Quản lý thẻ tại Hub - Quản lý';

    //     if (value.role == cs.Role_Card_BankUser) value.role = 'Quản lý thẻ tại Bank - Nhân viên';
    //     if (value.role == cs.Role_Card_BankLeader) value.role = 'Quản lý thẻ tại Bank - Trưởng phòng';
    //     if (value.role == cs.Role_Card_BankManager) value.role = 'Quản lý thẻ tại Bank - Quản lý';
    // }

    if (value && value.type) {
        if (value.type == cs.UserType_Admin) value.type = 'Quản trị hệ thống';
        if (value.type == cs.UserType_OP) value.type = 'Vận hành';
        if (value.type == cs.UserType_HR) value.type = 'Nhân sự';
        if (value.type == cs.UserType_Merchant) value.type = 'Đối tác';
    }

    if (value && value.locked) value.locked = 'Đã khóa';
    else value.locked = '';

    return value;
}

class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'name', title: 'Name' },
                { name: 'fullname', title: 'FullName' },
                { name: 'code', title: 'Code' },
                { name: 'phone', title: 'Phone' },
                { name: 'type', title: 'Type' },
                { name: 'role', title: 'Role' },
                // { name: 'adr_state', title: 'Adr State' },
                // { name: 'adr_district', title: 'Adr District' },
                // { name: 'adr_ward', title: 'Adr Ward' },
                { name: 'locked', title: ' ' },
                { name: 'action', title: 'Options' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'name', align: 'left', width: 250 },
                { columnName: 'fullname', align: 'left', width: 300 },
                { columnName: 'code', align: 'center', width: 150 },
                { columnName: 'phone', align: 'center', width: 200 },
                { columnName: 'type', align: 'left', width: 200 },
                { columnName: 'role', align: 'left', width: 250 },
                // { columnName: 'adr_state', align: 'center', width: 100 },
                // { columnName: 'adr_district', align: 'center', width: 100 },
                // { columnName: 'adr_ward', align: 'center', width: 100 },
                { columnName: 'locked', align: 'center', width: 100 },
                { columnName: 'action', align: 'center' }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'id', direction: 'desc' }],
            searchStatus: '',
            roles: [],

        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadRoles();
        this.loadData = this.loadData.bind(this);

    }

    loadRoles() {
        let queryString = `${rolesURL}?`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        roles: data.data
                    });
                } else {
                    this.setState({
                        roles: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => { });
    }

    formatRole(value) {
        let val = value.role;
        let role = this.state.roles.filter((el) => el.role_id === val)
        value.role = role[0].title;
        return value;
    }

    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const { pageSize, currentPage, sorting, searchName, searchFullName, searchRole, searchType } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;
        if (searchName) queryString = `${queryString}&name=${searchName}`;
        if (searchFullName) queryString = `${queryString}&fullname=${searchFullName}`;
        if (searchRole) queryString = `${queryString}&role=${searchRole}`;
        if (searchType) queryString = `${queryString}&type=${searchType}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                        data.data[i] = this.formatRole(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                if (name == 'searchType')
                    this.setState({ searchRole: null, currentPage: 0 }, () => {
                        this.loadData();
                    });
                else
                    this.setState({ currentPage: 0 }, () => {
                        this.loadData();
                    });
            }
        );
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.props.history.location.state.reload = false;
            this.loadData();
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New User"
                    component={Link}
                    to={'/user/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Create New User</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>
                <ExpansionPanel>
                    <ExpansionPanelSummary className={classes.search_heading} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Search ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">
                            <TextField
                                id="user_name"
                                label="User"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchName}
                                onChange={this.handleChange('searchName')}
                                margin="normal"
                            />

                            <TextField
                                id="user_fullname"
                                label="User Name"
                                type="search"
                                className={classes.search_field}
                                value={this.state.searchFullName}
                                onChange={this.handleChange('searchFullName')}
                                margin="normal"
                            />

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="searchType">Type</InputLabel>
                                <Select
                                    value={this.state.searchType}
                                    onChange={this.handleChange('searchType')}
                                    inputProps={{
                                        name: 'searchType',
                                        id: 'searchType'
                                    }}
                                >
                                    <MenuItem value={null}>
                                        All
                                    </MenuItem>
                                    <MenuItem value={cs.UserType_Admin}>Quản trị hệ thống</MenuItem>
                                    <MenuItem value={cs.UserType_OP}>Vận hành</MenuItem>
                                    <MenuItem value={cs.UserType_HR}>Nhân sự</MenuItem>
                                    <MenuItem value={cs.UserType_Merchant}>Đối tác</MenuItem>
                                    <MenuItem value={cs.UserType_Marketing}>Marketing</MenuItem>
                                    <MenuItem value={cs.UserType_Telesale}>Telesale</MenuItem>
                                    {/* <MenuItem value={cs.UserType_Debt}>Debt</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="searchRole">Role</InputLabel>
                                <Select
                                    value={this.state.searchRole}
                                    onChange={this.handleChange('searchRole')}
                                    inputProps={{
                                        name: 'searchRole',
                                        id: 'searchRole'
                                    }}
                                >
                                    <MenuItem value={null}>
                                        All
                                    </MenuItem>

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Admin) && (
                                        <MenuItem value={cs.Role_Admin}>Quản trị hệ thống</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_OP) && (
                                        <MenuItem value={cs.Role_OP_User}>Vận hành - Nhân viên</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_OP) && (
                                        <MenuItem value={cs.Role_OP_Leader}>Vận hành - Trưởng phòng</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_OP) && (
                                        <MenuItem value={cs.Role_OP_Manager}>Vận hành - Quản lý</MenuItem>
                                    )}


                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Merchant) && (
                                        <MenuItem value={cs.Role_Merchant_User}>Đối tác - Nhân viên</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Merchant) && (
                                        <MenuItem value={cs.Role_Merchant_Leader}>Đối tác - Trưởng phòng</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Merchant) && (
                                        <MenuItem value={cs.Role_Merchant_Manager}>Đối tác - Quản lý</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_HR) && (
                                        <MenuItem value={cs.Role_HR_User}>Nhân sự - Nhân viên</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_HR) && (
                                        <MenuItem value={cs.Role_HR_Leader}>Nhân sự - Trưởng phòng</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_HR) && (
                                        <MenuItem value={cs.Role_HR_Manager}>Nhân sự - Quản lý</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Marketing) && (
                                        <MenuItem value={cs.Role_Marketing_Merchant_User}>Marketing - Nhân viên</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Marketing) && (
                                        <MenuItem value={cs.Role_Marketing_Merchant_Leader}>Marketing - Trưởng phòng</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Marketing) && (
                                        <MenuItem value={cs.Role_Marketing_Merchant_Manager}>Marketing - Quản lý</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_User}>Telesale - Nhân viên</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_Leader}>Telesale - Trưởng phòng</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_Manager}>Telesale - Quản lý</MenuItem>
                                    )}

                                    {/* {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_User}>Telesale - Nhân viên</MenuItem>
                                    )}
                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_Leader}>Telesale - Trưởng phòng</MenuItem>
                                    )}

                                    {(!this.state.searchType || this.state.searchType == cs.UserType_Telesale) && (
                                        <MenuItem value={cs.Role_Telesale_Merchant_Manager}>Telesale - Quản lý</MenuItem>
                                    )} */}
                                </Select>
                            </FormControl>
                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            height="700px"
                        />
                        <TableHeaderRow
                            showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
                <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
                    <Route exact path="/user/create" component={CreateUser} />
                    <Route exact path="/user/edit/:id" component={EditUser} />
                    <Route exact path="/user/delete/:id" component={DeleteUser} />
                    <Route exact path="/user/reset_pass/:id" component={ResetPassword} />
                </ToastProvider>
            </div>
        );
    }
}

const getRowId = row => row.id;
UserList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    search_select_menu: {
        width: 400
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 300,
        minWidth: 120
    },

    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    cell_button: {
        width: 100,
        marginLeft: 2,
        marginRight: 2
    }
});

class ActionCell extends React.Component {
    isSysAdmin = () => {
        var username = this.props.row.name;
        return username == 'sysadmin';
    };

    render() {
        return (
            <Table.Cell
                style={{
                    padding: 2,
                    justifyContent: 'center'
                }}
            >
                <span>
                    <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        component={Link}
                        to={'/user/reset_pass/' + this.props.row.id}
                    >
                        <Icon>security</Icon>
                    </Button>

                    {!this.isSysAdmin() && (
                        <Button
                            color="primary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/user/edit/' + this.props.row.id}
                        >
                            <Icon>edit</Icon>
                        </Button>
                    )}

                    {!this.isSysAdmin() && (
                        <Button
                            color="secondary"
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 48
                            }}
                            component={Link}
                            to={'/user/delete/' + this.props.row.id}
                        >
                            <Icon>remove_circle</Icon>
                        </Button>
                    )}
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                margin: 1,
                padding: 1,
                paddingLeft: 40
            }}
        />
    );
};

const RowComponent = props => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = props => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = props => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                paddingLeft: 40,
                alignContent: 'center'
            }}
        />
    );
};
const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(UserList));
