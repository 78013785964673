import cs from "./const";

export const isAdmin = () => {
    var role = '';
    var user = localStorage.getItem(cs.System_Code + '-admin-user');
    if (user) role = JSON.parse(String(user)).role;
    if (role === cs.Role_Sys_Admin || role === cs.Role_Admin) return true;
    else return false;
};

export function hideMobile(tel) {
    var tel = tel.toString();
    return tel.substr(0, 3) + '****' + tel.substr(7);
}

export function getRole() {
    var role = '';
    var user = localStorage.getItem(cs.System_Code + '-admin-user');
    if (user) role = JSON.parse(String(user)).role;
    return role;
}
