import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';

import cs from 'const.js';
// import CreateAgentProduct from './CreateAgentProduct';
// import DeleteAgentProduct from './DeleteAgentProduct';
const productURL = cs.BaseURL + '/product/detail';
const URL = cs.BaseURL + '/product/agent-assign/list';

function formatValue(value) {
    return value;
}

class ProductAgents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mc_product_id: this.props.match.params.id,
            mc_product_code: '',
            columns: [{ name: 'agent_name', title: 'Agent' }, { name: 'agent_phone', title: 'Phone' }],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'agent_name', align: 'left', width: 120 },
                { columnName: 'agent_phone', align: 'center', width: 120 }
            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            sorting: [{ columnName: 'agent_name', direction: 'desc' }],
            loading: true
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadProduct();
    }
    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }
    componentDidMount() {
        this.loadData();
    }

    loadProduct() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = `${productURL}?new=true&id=${this.props.match.params.id}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    this.setState({
                        mc_code: data.data.mc_code,
                        mc_product_name: data.data.name,
                        mc_product_code: data.data.code,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
                this.loadData();
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    queryString() {
        const { mc_product_code, pageSize, currentPage, sorting } = this.state;

        let queryString = `${URL}?new=true&mc_product_code=${mc_product_code}&pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }
    loadData() {
        this.setState({
            loading: true
        });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }
    handleDialogDisAgree = () => {
        this.props.history.push({
            pathname: '/product/',
            state: { reload: true }
        });
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title">
                    <DialogTitle id="edit_agent_form-dialog-title">{this.state.mc_product_code} - Agents</DialogTitle>
                    <DialogContent>
                        <Paper className={classes.grid_Container}>
                            <Grid rows={rows} columns={columns} getRowId={getRowId}>
                                <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={this.changeCurrentPage}
                                    onPageSizeChange={this.changePageSize}
                                    pageSize={pageSize}
                                />
                                <CustomPaging totalCount={totalCount} />
                                <VirtualTable
                                    tableComponent={TableComponent}
                                    columnExtensions={tableColumnExtensions}
                                    height="400"
                                />
                                <TableHeaderRow showSortingControls className={classes.grid_header} />
                                <PagingPanel pageSizes={pageSizes} />
                            </Grid>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisAgree} color="primary">
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const getRowId = row => row.id;
ProductAgents.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    }
});

const TableComponentBase = ({ classes, ...restProps }) => <VirtualTable.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(ProductAgents));
