import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withToastManager } from 'react-toast-notifications';

import cs from 'const';
import DialogWrapper from 'components/shared/DialogWrapper';

const deleteUserURL = cs.BaseURL + '/general/delete';
const styles = theme => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class DeleteUser extends React.Component {
    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert('Wrong information !');
            return;
        }
        var user_id = this.props.match.params.id;
        let queryString = `${deleteUserURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: user_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('User is deleted !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/general',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Do you want to delete User ?'} {...this.props}>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

DeleteUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(DeleteUser));
