import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';

import cs from 'const';
import { isAdmin } from 'service';

import UserList from 'components/pages/user/UserList';
import UserPermList from 'components/pages/user_perm/UserPermList';
// import AgentList from 'components/pages/agent/AgentList';
// import AgentLocationList from 'components/pages/agent_location/AgentLocationList';
// import AgentProductList from 'components/pages/agent_product/AgentProductList';
import ProductList from 'components/pages/product/ProductList';
import MerchantList from 'components/pages/merchant/MerchantList';
import OPAllocationList from 'components/pages/op-allocation/OPAllocationList';
import UserGeneralList from 'components/pages/general/UserGeneralList';
import AgentTelesaleList from 'components/pages/telesale/AgentTelesaleList';
import ImportManager from 'components/pages/import/ImportManager';
import ImportLeader from 'components/pages/import/ImportLeader';
import ImportMerchant from 'components/pages/import/ImportMerchant';
import ImportProduct from 'components/pages/import/ImportProduct';
import ImportTelesale from 'components/pages/import/ImportTelesale';

// const DashboardArea = () => (
//     <div>
//         <Typography variant="h4" gutterBottom component="h2">
//             Thống kê
//         </Typography>
//         <Typography component="div" className={styles.tableContainer}>
//             <Dashboard />
//         </Typography>
//     </div>
// );

const styles = (theme) => ({
    tableContainer: {
        height: '100vh'
    },
});

const UserArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            DSA User
        </Typography>
        <div className={styles.tableContainer}>
            <UserList />
        </div>
    </div>
);

const UserPermArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            User Permission
        </Typography>
        <div className={styles.tableContainer}>
            <UserPermList />
        </div>
    </div>
);

const OPAllocationArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            OP Allocation
        </Typography>
        <div className={styles.tableContainer}>
            <OPAllocationList />
        </div>
    </div>
);

const GeneralArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            {isAdmin() ? ' General User' : 'User'}
        </Typography>
        <div className={styles.tableContainer}>
            <UserGeneralList />
        </div>
    </div>
);

// const AgentArea = () => (
//     <div>
//         <Typography variant="h5" gutterBottom component="h2">
//             Quản lý DSA
//         </Typography>
//         <div className={styles.tableContainer}>
//             <AgentList />
//         </div>
//     </div>
// );
// const AgentLocationArea = () => (
//     <div>
//         <Typography variant="h5" gutterBottom component="h2">
//             Phân quyền địa bàn
//         </Typography>
//         <div className={styles.tableContainer}>
//             <AgentLocationList />
//         </div>
//     </div>
// );
// const AgentProductArea = () => (
//     <div>
//         <Typography variant="h5" gutterBottom component="h2">
//             Phân quyền sản phẩm
//         </Typography>
//         <div className={styles.tableContainer}>
//             <AgentProductList />
//         </div>
//     </div>
// );
// const LeaderArea = () => (
//     <div>
//         <Typography variant="h5" gutterBottom component="h2">
//             Quản lý Trưởng Nhóm
//         </Typography>
//         <div className={styles.tableContainer}>
//             <LeaderList />
//         </div>
//     </div>
// );

const ProductArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Product
        </Typography>
        <div className={styles.tableContainer}>
            <ProductList />
        </div>
    </div>
);

const MerchantArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Merchant
        </Typography>
        <div className={styles.tableContainer}>
            <MerchantList />
        </div>
    </div>
);

const TelesaleArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Telesale Agent
        </Typography>
        <div className={styles.tableContainer}>
            <AgentTelesaleList />
        </div>
    </div>
);

const ImportManagerArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Tạo Giám đốc hàng loạt
        </Typography>
        <div className={styles.tableContainer}>
            <ImportManager />
        </div>
    </div>
);

const ImportLeaderArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Tạo Trưởng phòng hàng loạt
        </Typography>
        <div className={styles.tableContainer}>
            <ImportLeader />
        </div>
    </div>
);

const ImportMerchantArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Tạo Merchant hàng loạt
        </Typography>
        <div className={styles.tableContainer}>
            <ImportMerchant />
        </div>
    </div>
);

const ImportProductArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Tạo Product hàng loạt
        </Typography>
        <div className={styles.tableContainer}>
            <ImportProduct />
        </div>
    </div>
);

const ImportTelesaleArea = () => (
    <div>
        <Typography variant="h5" gutterBottom component="h2">
            Tạo Telesale hàng loạt
        </Typography>
        <div className={styles.tableContainer}>
            <ImportTelesale />
        </div>
    </div>
);
function AppRoute() {
    return (
        <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
            <Route exact path="/" component={GeneralArea} />
            <Route path="/general" component={GeneralArea} />

            { isAdmin() && (
                <>
                    <Route path="/user" component={UserArea} />
                    <Route path="/user_perm" component={UserPermArea} />
                    <Route path="/op_allocation" component={OPAllocationArea} />
                    <Route path="/merchant" component={MerchantArea} />
                    <Route exact path="/import/merchant" component={ImportMerchantArea} />
                </>
            )}
            <Route exact path="/import/manager" component={ImportManagerArea} />
            <Route exact path="/import/leader" component={ImportLeaderArea} />
            <Route exact path="/import/telesale" component={ImportTelesaleArea} />
            <Route exact path="/import/product" component={ImportProductArea} />
            <Route path="/telesale" component={TelesaleArea} />
            <Route path="/product" component={ProductArea} />
        </ToastProvider>
    );
}

export default AppRoute;
