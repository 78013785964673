import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import cs from 'const';
import { isAdmin } from 'service';
import DialogWrapper from 'components/shared/DialogWrapper';

const createUserURL = cs.BaseURL + '/general/create';
const MerchantURL = cs.BaseURL + '/merchant/list';
const UserDetailURL = cs.BaseURL + '/user/detail';
const UserRoleURL = cs.BaseURL + '/user-role/list';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

class CreateUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_roles: [
                {
                    role_id: cs.Role_Telesale_OP_User,
                    title: 'TelesaleOP - Nhân viên'
                },
                {
                    role_id: cs.Role_Telesale_OP_Leader,
                    title: 'TelesaleOP - Trưởng phòng'
                },
                {
                    role_id: cs.Role_Telesale_OP_Manager,
                    title: 'TelesaleOP - Quản lý'
                },
                {
                    role_id: cs.Role_Telesale_Merchant_User,
                    title: 'TelesaleMerchant - Nhân viên'
                },
                {
                    role_id: cs.Role_Telesale_Merchant_Leader,
                    title: 'TelesaleMerchant - Trưởng phòng'
                },
                {
                    role_id: cs.Role_Telesale_Merchant_Manager,
                    title: 'TelesaleMerchant - Quản lý'
                },
                {
                    role_id: cs.Role_Marketing_OP_User,
                    title: 'MarketingOP - Nhân viên'
                },
                {
                    role_id: cs.Role_Marketing_OP_Leader,
                    title: 'MarketingOP - Trưởng phòng'
                },
                {
                    role_id: cs.Role_Marketing_OP_Manager,
                    title: 'MarketingOP - Quản lý'
                },
                {
                    role_id: cs.Role_Marketing_Merchant_User,
                    title: 'MarketingMerchant - Nhân viên'
                },
                {
                    role_id: cs.Role_Marketing_Merchant_Leader,
                    title: 'MarketingMerchant - Trưởng phòng'
                },
                {
                    role_id: cs.Role_Marketing_Merchant_Manager,
                    title: 'MarketingMerchant - Quản lý'
                }, {
                    role_id: cs.Role_Merchant_Admin,
                    title: 'Admin-HT Telesale-Đối tác'
                }, {
                    role_id: cs.Role_Mc_Board_Of_Control_Member,
                    title: 'Thành viên ban kiểm soát-Đối tác'
                }
                ,  {
                    role_id: cs.Role_Telesale_Mc_Branch_Director,
                    title: 'Giám đốc chi nhánh-HT Telesale-Đối tác'
                }
            ],
            name: '',
            fullname: '',
            phone: '',
            code: '',
            role: '',
            roles: [],
            codeMerchant: '',
            merchant: [],
            mc_code: 'Merchant'
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        if (isAdmin()) this.loadMerchant();
        else {
            this.loadUserRole();
            this.loadMerchantTls();
        }
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.role) {
            window.alert('Please input information !');
            return;
        }

        if ((this.state.role === cs.Role_Merchant_Admin || this.state.role === cs.Role_Mc_Board_Of_Control_Member) && !this.state.codeMerchant) {
            window.alert('Admin must select merchant!');
            return;
        }
        let queryString = `${createUserURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name,
                fullname: this.state.fullname,
                phone: this.state.phone,
                code: this.state.code,
                role: this.state.role,
                mc_code: this.state.codeMerchant
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('User is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/general',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => { });
    };

    handleChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };

    loadUserRole() {
        fetch(UserRoleURL, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        user_roles: data.data
                    });
                } else {
                    this.setState({
                        user_roles: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    user_roles: []
                });
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });
    }

    loadMerchantTls() {
        var user = localStorage.getItem(cs.System_Code + '-admin-user');
        if (user) {
            const username = JSON.parse(String(user)).name;

            const queryString = `${UserDetailURL}?name=${username}`;
            fetch(queryString, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        this.setState({
                            mc_code: data.data.mc_code
                        });
                    } else {
                        this.setState({
                            mc_code: 'Merchant'
                        });
                        if (
                            data &&
                            (data.code == cs.erAuthenticationFailed.code ||
                                data.code == cs.erAccountIsLocked.code)
                        ) {
                            sessionStorage.clear();
                            localStorage.clear();
                            window.location.reload();
                        }
                    }
                })
                .catch(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                });
        }
    }

    loadMerchant() {
        let queryString = `${MerchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchant: data.data
                    });
                } else {
                    this.setState({
                        merchant: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchant: []
                });
            });
    }

    render() {
        const { classes } = this.props;
        
        return (
            <>
                <DialogWrapper title={'Create User'} {...this.props}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            label="User Name"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_fullname"
                            value={this.state.fullname}
                            onChange={this.handleChange('fullname')}
                            label="User FullName"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_phone"
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            label="Phone Number"
                            type="number"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="create_user_code"
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            label="Code"
                            type="text"
                        />
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_user_role">Role</InputLabel>
                            <Select
                                value={this.state.role}
                                onChange={this.handleChange('role')}
                                inputProps={{
                                    name: 'role',
                                    id: 'create_user_role'
                                }}
                            >
                                {/* <MenuItem value={null}>
                                    <em />
                                </MenuItem> */}
                                {this.state.user_roles.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.role_id}>
                                            {item.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {isAdmin() ? (
                            <FormControl fullWidth>
                                <InputLabel htmlFor="create_user_merchant">Merchant</InputLabel>
                                <Select
                                    value={this.state.codeMerchant}
                                    onChange={this.handleChange('codeMerchant')}
                                    inputProps={{
                                        name: 'codeMerchant',
                                        id: 'codeMerchant'
                                    }}
                                >
                                    <MenuItem value={null}>
                                        All
                                    </MenuItem>
                                    {this.state.merchant.map((pro, index) => (
                                        <MenuItem key={index} value={pro.code}>
                                            {pro.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <FormControl fullWidth disabled>
                                <InputLabel htmlFor="create_user_merchant">
                                    {this.state.mc_code}
                                </InputLabel>
                                <Select>
                                    <MenuItem value={null}></MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Create New
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </>
        );
    }
}

CreateUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateUser));
