import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import cs from 'const.js';
import { isAdmin } from 'service.js';
import moment from 'moment-timezone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const URL = cs.BaseURL + '/telesale/detail';
const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';
const updateURL = cs.BaseURL + '/telesale/update';
const merchantURL = cs.BaseURL + '/telesale/merchant';

const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
function TabContainer(props) {
    return <Paper style={{ padding: 8 * 6 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class AgentTelesaleDetail extends React.Component {
    state = {
        tabNum: 0,
        states: [],
        districts: [],
        wards: [],
        merchant: [],

        id: this.props.match.params.id,
        code: '',
        name: '',
        gender: true,
        birthday: moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'),
        national_id: '',
        national_id_place: '',
        national_id_date: moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD'),
        phone: '',
        email: '',
        mc_code: '',

        adr_state_code: '',
        adr_district_code: '',
        adr_ward_code: '',
        adr_street: '',
        adr_role_code: '',

        education_level: '',

        sub_region: '',

        max_order_qty: -1,
        allow_create_order: false,
        locked: false
    };
    constructor(props) {
        super(props);

        this.loadData();
        this.loadStates();
        this.loadMerchant();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?new=true&id=${id}`;
        return queryString;
    }

    loadStates() {
        let queryString = `${statesURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        states: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        let queryString = `${districtsURL}?state_code=${this.state.adr_state_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    data.data.unshift({ adr_district: '', adr_district_code: null });
                    this.setState({
                        districts: data.data
                    });
                } else {
                    this.setState({
                        districts: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {});
    }

    loadWards() {
        let queryString = `${wardsURL}?district_code=${this.state.adr_district_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    data.data.unshift({ adr_ward: '', adr_ward_code: null });
                    this.setState({
                        wards: data.data
                    });
                } else {
                    this.setState({
                        wards: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {});
    }

    loadMerchant() {
        let queryString = `${merchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchant: data.data
                    });
                } else {
                    this.setState({
                        merchant: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchant: []
                });
            });
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    data = data.data;
                    console.log(data);
                    this.setState(
                        {
                            code: data.code,
                            name: data.name,
                            gender: data.gender,
                            birthday: moment
                                .unix(data.birthday)
                                .tz('Asia/Ho_Chi_Minh')
                                .format('YYYY-MM-DD'),
                            national_id: data.national_id,
                            national_id_date: moment
                                .unix(data.national_id_date)
                                .tz('Asia/Ho_Chi_Minh')
                                .format('YYYY-MM-DD'),
                            national_id_place: data.national_id_place,
                            phone: data.phone,
                            email: data.email,
                            mc_code: data.mc_code,

                            adr_state_code: data.adr_state_code,
                            adr_district_code: data.adr_district_code,
                            adr_ward_code: data.adr_ward_code,
                            adr_street: data.adr_street,

                            education_level: data.education_level,

                            sub_region: data.sub_region,
                            adr_role_code: data.role,

                            max_order_qty:
                                data.max_order_qty && data.max_order_qty >= 0
                                    ? data.max_order_qty
                                    : -1,
                            allow_create_order: data.allow_create_order == 1,
                            locked: data.locked,
                            loading: false
                        },
                        () => {
                            this.loadDistricts();
                            this.loadWards();
                        }
                    );
                } else {
                    this.setState({
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }
    
    handleDialogAgree = () => {
        if (
            !this.state.name ||
            !this.state.phone ||
            !this.state.mc_code ||
            !this.state.adr_role_code
        ) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${updateURL}`;
        let obj = {
            id: this.state.id,
            code: this.state.code,
            name: this.state.name,
            gender: this.state.gender ? 1 : 0,
            birthday: moment(this.state.birthday).unix(),
            national_id: this.state.national_id,
            national_id_place: this.state.national_id_place,
            national_id_date: moment(this.state.national_id_date).unix(),
            phone: this.state.phone,
            email: this.state.email,
            mc_code: this.state.mc_code,

            adr_state_code: this.state.adr_state_code,
            adr_district_code: this.state.adr_district_code,
            adr_ward_code: this.state.adr_ward_code,
            adr_street: this.state.adr_street,
            adr_role_code: this.state.adr_role_code,

            sub_region: this.state.sub_region,
            education_level: this.state.education_level,

            max_order_qty: this.state.max_order_qty,
            allow_create_order: this.state.allow_create_order ? 1 : 0,
            locked: this.state.locked ? 1 : 0
        };

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                 ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Agent Telesales is updated !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/telesale',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleChange = (name) => (event) => {
        if (name == 'locked') this.setState({ locked: event.target.checked });
        else if (name == 'allow_create_order')
            this.setState({ allow_create_order: event.target.checked });
        else if (name == 'gender') this.setState({ gender: event.target.checked });
        else {
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == 'adr_state_code') this.loadDistricts();
                    if (name == 'adr_district_code') this.loadWards();
                }
            );
        }
    };

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };
    render() {
        const { classes } = this.props;
        const { tabNum } = this.state;

        return (
            <>
                <Dialog
                    onClose={this.props.history.goBack}
                    open={true}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle
                        id="create_user_form-dialog-title"
                        onClose={this.props.history.goBack}
                    >
                        Update Telesale Agent Informations
                    </DialogTitle>
                    <DialogContent>
                        <AppBar position="static">
                            <Tabs value={tabNum} onChange={this.handleTabChange}>
                                <Tab label="Cá nhân" />
                                <Tab label="Địa chỉ" />
                                <Tab label="Quản lý" />
                            </Tabs>
                        </AppBar>
                        {tabNum === 0 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_code"
                                    required={true}
                                    value={this.state.code}
                                    onChange={this.handleChange('code')}
                                    label="Code"
                                    type="name"
                                />

                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_name"
                                    required={true}
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    label="Họ và tên"
                                    type="name"
                                />

                                <div fullWidth className={classes.locked_button}>
                                    <Switch
                                        checked={this.state.gender}
                                        value={this.state.gender}
                                        onChange={this.handleChange('gender')}
                                        color="primary"
                                    />
                                    {this.state.gender ? 'Nam' : 'Nữ'}
                                </div>
                                <TextField
                                    fullWidth
                                    id="edit_birthday"
                                    label="Ngày sinh"
                                    required={true}
                                    value={this.state.birthday}
                                    onChange={this.handleChange('birthday')}
                                    type="date"
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_national_id"
                                    required={true}
                                    value={this.state.national_id}
                                    onChange={this.handleChange('national_id')}
                                    label="CMND"
                                    type="number"
                                />
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_national_id_date"
                                    value={this.state.national_id_date}
                                    onChange={this.handleChange('national_id_date')}
                                    label="Ngày cấp"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_national_id_place"
                                    required={true}
                                    value={this.state.national_id_place}
                                    onChange={this.handleChange('national_id_place')}
                                    label="Nơi cấp"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    disabled
                                    margin="dense"
                                    id="edit_phone"
                                    required={true}
                                    value={this.state.phone}
                                    onChange={this.handleChange('phone')}
                                    label="Số điện thoại"
                                    type="number"
                                />
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_email"
                                    value={this.state.email}
                                    onChange={this.handleChange('email')}
                                    label="email"
                                    type="email"
                                />
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_education_level">
                                        Trình độ học vấn
                                    </InputLabel>
                                    <Select
                                        value={this.state.education_level}
                                        onChange={this.handleChange('education_level')}
                                        inputProps={{
                                            name: 'education_level',
                                            id: 'edit_education_level'
                                        }}
                                    >
                                        <MenuItem value="Tiến sĩ">Tiến sĩ</MenuItem>
                                        <MenuItem value="Thạc sĩ">Thạc sĩ</MenuItem>
                                        <MenuItem value="Cử nhân">Cử nhân</MenuItem>
                                        <MenuItem value="CĐ/TC/ Học nghề">CĐ/TC/ Học nghề</MenuItem>
                                        <MenuItem value="THPT">THPT</MenuItem>
                                        <MenuItem value="THCS">THCS</MenuItem>
                                    </Select>
                                </FormControl>
                            </TabContainer>
                        )}
                        {tabNum === 1 && (
                            <TabContainer>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_adr_state">
                                        Địa chỉ hiện tại - Tỉnh/thành phố (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.adr_state_code}
                                        onChange={this.handleChange('adr_state_code')}
                                        inputProps={{
                                            name: 'adr_state',
                                            id: 'edit_adr_state'
                                        }}
                                    >
                                        {console.log(this.state.states)}
                                        {console.log(this.state.adr_state_code)}
                                        {this.state.states.map((pro) => (
                                            <MenuItem value={pro.state_code}>
                                                {pro.state_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>{' '}
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_adr_district">
                                        Địa chỉ hiện tại - Quận / huyện (*)
                                    </InputLabel>
                                    <Select
                                        value={this.state.adr_district_code}
                                        onChange={this.handleChange('adr_district_code')}
                                        inputProps={{
                                            name: 'adr_district',
                                            id: 'edit_adr_district'
                                        }}
                                    >
                                        {console.log(this.state.adr_district_code)}
                                        {this.state.districts.map((pro) => (
                                            <MenuItem value={pro.district_code}>
                                                {pro.district_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>{' '}
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_adr_ward">
                                        Địa chỉ hiện tại - Xã/Phường
                                    </InputLabel>
                                    <Select
                                        value={this.state.adr_ward_code}
                                        onChange={this.handleChange('adr_ward_code')}
                                        inputProps={{
                                            name: 'adr_ward',
                                            id: 'edit_adr_ward'
                                        }}
                                    >
                                        {this.state.wards.map((pro) => (
                                            <MenuItem value={pro.ward_code}>
                                                {pro.ward_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_adr_street"
                                    value={this.state.adr_street}
                                    onChange={this.handleChange('adr_street')}
                                    label="Địa chỉ hiện tại - Đường phố"
                                    type="text"
                                />
                            </TabContainer>
                        )}

                        {tabNum === 2 && (
                            <TabContainer>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="edit_sub_region">Sub Region</InputLabel>
                                    <Select
                                        value={this.state.sub_region}
                                        onChange={this.handleChange('sub_region')}
                                        inputProps={{
                                            name: 'edit_sub_region',
                                            id: 'edit_sub_region'
                                        }}
                                    >
                                        {this.state.states.map((pro) => (
                                            <MenuItem value={pro.state_code}>
                                                {pro.state_code && <div>{pro.state_name}</div>}
                                                {!pro.state_code && <div>{pro.state_name}</div>}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="create_adr_role_code">
                                        Phân loại
                                    </InputLabel>
                                    <Select
                                        value={this.state.adr_role_code}
                                        onChange={this.handleChange('adr_role_code')}
                                        inputProps={{
                                            name: 'adr_role_code',
                                            id: 'create_adr_role_code'
                                        }}
                                    >
                                        <MenuItem value={cs.Role_Agent_Telesale}>
                                            Nhân viên telesale
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {isAdmin() ? (
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="create_user_merchant">
                                                Merchant
                                            </InputLabel>
                                            <Select
                                                value={this.state.mc_code}
                                                onChange={this.handleChange('mc_code')}
                                                inputProps={{
                                                    name: 'codeMerchant',
                                                    id: 'codeMerchant'
                                                }}
                                            >
                                                <MenuItem value={null}>
                                                    <em />
                                                </MenuItem>
                                                {this.state.merchant.map((pro) => (
                                                    <MenuItem value={pro.code}>{pro.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                ) : (
                                    <FormControl fullWidth disabled>
                                        <InputLabel htmlFor="create_user_merchant">
                                            {this.state.mc_code}
                                        </InputLabel>
                                        <Select>
                                            <MenuItem value={null}></MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_max_order_qty"
                                    value={this.state.max_order_qty}
                                    onChange={this.handleChange('max_order_qty')}
                                    label="Số lượng hồ sơ tối đa tại thời điểm"
                                    type="number"
                                />

                                <div fullWidth>
                                    <Switch
                                        checked={this.state.allow_create_order}
                                        value={this.state.allow_create_order}
                                        onChange={this.handleChange('allow_create_order')}
                                        color="primary"
                                    />
                                    {this.state.allow_create_order
                                        ? 'Cho phép tạo hồ sơ thủ công'
                                        : 'Không cho phép tạo hồ sơ thủ công'}
                                </div>

                                <div fullWidth className={classes.locked_button}>
                                    <Switch
                                        checked={this.state.locked}
                                        value={this.state.locked}
                                        onChange={this.handleChange('locked')}
                                        color="primary"
                                    />
                                    {this.state.locked ? 'Khoá tài khoản' : 'TK đang hiệu lực'}
                                </div>
                            </TabContainer>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

AgentTelesaleDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(AgentTelesaleDetail));
