import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormData from 'form-data';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import { DropzoneArea } from 'material-ui-dropzone';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';

import cs from 'const';
import { getRole, isAdmin } from 'service';
import format from 'string-format';
import moment from 'moment-timezone';
import ResultPanel from 'components/shared/import/ResultPanel';
import MockupFilePanel from 'components/shared/import/MockupFilePanel';

const ImportLeaderTelesaleURL = cs.BaseURL + '/general/import-leader';
const merchantURL = cs.BaseURL + '/merchant/list';
const UserDetailURL = cs.BaseURL + '/user/detail';

function formatErrors(errors) {
    const errorlist = [];
    for (var i = 0; i < errors.length; i++) {
        try {
            var row = JSON.parse(errors[i]);
            if (row.error) {
                row.error = format('Dòng {0} : {1}.', row.rowNo + 1, row.error);

                errorlist.push(row);
            }

            // if (row && row.code == cs.erInvalidRequest.code)
            //     row.error = format('Dòng {0} : File không hợp lệ.', row.rowNo + 1);
            // else if (row && row.code == cs.erProductNotExisted.code)
            //     row.error = format('Dòng {0} : Mã sản phẩm không tồn tại.', row.rowNo + 1);
            // else if (row && row.code == cs.erProductInvalid.code)
            //     row.error = format('Dòng {0} : Mã sản phẩm không hợp lệ.', row.rowNo + 1);
            // else if (row && row.code == cs.erProductIsLocked.code)
            //     row.error = format('Dòng {0} : Mã sản phẩm đã bị khóa .', row.rowNo + 1);
            // else if (row && row.code == cs.erProductCodeCannotEmpty.code)
            //     row.error = format('Dòng {0} : Mã sản phẩm không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erPhoneNumberCannotEmpty.code)
            //     row.error = format('Dòng {0} : Số điện thoại không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erNameCannotEmpty.code)
            //     row.error = format('Dòng {0} : Tên không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erGenderCannotEmpty.code)
            //     row.error = format('Dòng {0} : Giới tính không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erStateCannotEmpty.code)
            //     row.error = format('Dòng {0} : Tỉnh/thành phố không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erDistrictCannotEmpty.code)
            //     row.error = format('Dòng {0} : Quận/huyện không được trống.', row.rowNo + 1);
            // else if (row && row.code == cs.erInvalidState.code)
            //     row.error = format('Dòng {0} : Tỉnh/thành phố không hợp lệ.', row.rowNo + 1);
            // else if (row && row.code == cs.erInvalidDistrict.code)
            //     row.error = format('Dòng {0} : Quận/huyện không hợp lệ.', row.rowNo + 1);
            // else if (row && row.code == cs.erCannotCreateObject.code)
            //     row.error = format('Dòng {0} : Không thể tạo Agent.', row.rowNo + 1);
            // else if (row && row.code == cs.erCannotUpdateObject.code)
            //     row.error = format('Dòng {0} : Không thể câp nhật Agent.', row.rowNo + 1);
        } catch (er) {
            if (er) console.log(i + ':error:' + JSON.stringify(er));
        }
    }
    console.log(errorlist);
    return errorlist;
}

function formatResults(results) {
    const resultlist = [];
    for (var i = 0; i < results.length; i++) {
        try {
            var row = JSON.parse(results[i]);
            if (!row.error) {
                row.msg = format(
                    'Dòng {0}: Tên đăng nhập: {1}',
                    row.rowNo + 1,
                    row.data.name
                );
                resultlist.push(row);
            }
        } catch (er) {
            if (er) console.log(i + ':error:' + JSON.stringify(er));
        }
    }
    return resultlist;
}

export class ImportLeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            errors: [],
            results: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false,
            merchants: [],
            mc_code: '',
        };
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);

        this.handleClose = this.handleClose.bind(this);

        isAdmin() ? this.loadMerchants() : this.loadMerchantTls();
    }

    resetState() {
        this.setState({
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        });
    }

    handleClose = () => {
        this.resetState();
    };

    loadMerchants() {
        let queryString = `${merchantURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    this.setState({
                        merchants: data.data
                    });
                } else {
                    this.setState({
                        merchants: []
                    });

                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    merchants: []
                });
            });
    }

    loadMerchantTls() {
        var user = localStorage.getItem(cs.System_Code + '-admin-user');
        if (user) {
            const username = JSON.parse(String(user)).name;

            const queryString = `${UserDetailURL}?name=${username}`;
            fetch(queryString, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        this.setState({
                            mc_code: data.data.mc_code
                        });
                    } else {
                        this.setState({
                            mc_code: 'Merchant'
                        });
                        if (
                            data &&
                            (data.code == cs.erAuthenticationFailed.code ||
                                data.code == cs.erAccountIsLocked.code)
                        ) {
                            sessionStorage.clear();
                            localStorage.clear();
                            window.location.reload();
                        }
                    }
                })
                .catch(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                });
        }
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleDialogAgree = () => {
        if (!this.state.mc_code) {
            this.props.toastManager.add('Vui lòng chọn merchant', {
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: true
            });
            return;
        }

        this.setState({
            isProcessing: true,
            isFileImportProcessed: false,
            isFileImported: false,
            errors: []
        });

        let queryString = `${ImportLeaderTelesaleURL}`;

        const formData = new FormData();
        formData.append('excel-file', this.state.file);
        formData.append('mc_code', this.state.mc_code);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-admin-token') }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                 ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                var data = data.data;

                this.setState({
                    isProcessing: false,
                    isFileImported: true,
                    errors: formatErrors(data.errors),
                    results: formatResults(data.results)
                });
                if (this.state.isFileImported) {
                    this.props.toastManager.add('Đã import file. Xem kết quả bên dưới', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
            })
            .catch(() => {
                this.props.toastManager.add('Có lỗi khi import file!', {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: true
                });
                this.resetState();
            });
    };

    handleDropZoneChange(files) {
        if (files && files instanceof Array && files.length > 0) {
            this.setState({
                file: files[0],
                isProcessing: true,
                isFileValid: false,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });
            if (
                files[0].type === 'application/vnd.ms-excel' ||
                files[0].type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                this.setState({
                    isProcessing: false,
                    isFileValid: true,
                    errors: []
                });
            } else {
                console.log(files[0].type);
                this.setState({
                    isProcessing: false,
                    isFileValid: false,
                    errors: []
                });
            }
        } else this.resetState();
    }

    handleReloadPage() {
        window.location.reload();
    }

    render() {
        const { classes } = this.props;

        if (this.props.open != this.state.open && this.props.open) {
            this.state.open = this.props.open;
        }

        var importURL;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            importURL = 'http://192.168.1.127:4302/images/sample/bo_phan_quan_ly_van_hanh.xlsx';
        else
            importURL = 'https://media.aidriven.goldengate.biz.vn/images/sample/bo_phan_quan_ly_van_hanh.xlsx';
        return (
            <>
                <DialogContent>
                    <MockupFilePanel importURL={importURL} />

                    {isAdmin() ? (
                        <>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="merchantId">Merchant (*)</InputLabel>
                                <Select
                                    value={this.state.mc_code}
                                    onChange={this.handleChange('mc_code')}
                                    inputProps={{
                                        name: 'merchant',
                                        id: 'merchantId'
                                    }}
                                >
                                    {this.state.merchants.map((item) => (
                                        <MenuItem value={item.code}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    ) : (
                        <FormControl fullWidth disabled>
                            <InputLabel htmlFor="create_user_merchant">
                                {this.state.mc_code}
                            </InputLabel>
                            <Select>
                                <MenuItem value={null}></MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <div style={{ marginTop: '20px' }}></div>

                    {/* select file area */}
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch"
                        spacing={1}
                        className={classes.gridContainer}
                    >
                        {this.state.isProcessing ? (
                            <div style={{ height: 150 }}>
                                <CircularProgress
                                    style={{
                                        position: 'relative',
                                        left: '45%',
                                        top: '70px'
                                    }}
                                />
                            </div>
                        ) : (
                            <Grid item gutterBottom>
                                <DropzoneArea
                                    acceptedFiles={[
                                        'application/vnd.ms-excel',
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    ]}
                                    dropzoneText={
                                        this.state.file && this.state.file.name
                                            ? this.state.file.name
                                            : 'Upload file exel theo mẫu'
                                    }
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviewsInDropzone={false}
                                    onChange={this.handleDropZoneChange}
                                    dropZoneClass={classes.dropZone}
                                />
                            </Grid>
                        )}

                        <Grid item gutterBottom className={classes.statusItem}>
                            {this.state.file &&
                                !this.state.isProcessing &&
                                this.state.isFileValid && (
                                    <Typography variant="body1">File hợp lệ !</Typography>
                                )}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleReloadPage} color="primary">
                        Tải lại trang
                    </Button>
                    {this.state.file &&
                        !this.state.isProcessing &&
                        this.state.isFileValid &&
                        !this.state.isFileImportProcessed &&
                        !this.state.isFileImported && (
                            <Button onClick={this.handleDialogAgree} variant="contained" color="primary">
                                Tạo Leader Telesale
                            </Button>
                        )}
                </DialogActions>

                <ResultPanel errors={this.state.errors} results={this.state.results} />
            </>
        );
    }
}

const styles = (theme) => ({
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    erItem: {
        secondary: 'pink'
    },
    statusItem: {
        marginTop: 5
    }
});

export default withToastManager(withStyles(styles)(ImportLeader));
