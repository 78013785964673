import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment-timezone';

import cs from 'const';
import CreateMerchant from './CreateMerchant';
import EditMerchant from './EditMerchant';
import DeleteMerchant from './DeleteMerchant';
import ImportMerchant from './ImportMerchant';
import DataTable from 'components/shared/DataTable';
const URL = cs.BaseURL + '/merchant/list';

function formatValue(value) {
    if (value && value.locked) value.locked = 'Locked';
    else value.locked = '';

    if (value && value.contact_person_DOB)
        value.contact_person_DOB = moment(value.contact_person_DOB)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');

    if (value && value.contact_id_issue_date)
        value.contact_id_issue_date = moment(value.contact_id_issue_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');

    if (value && value.contact_person_gender !== null) {
        console.log(value.contact_person_gender, typeof value.contact_person_gender)

        value.contact_person_gender = value.contact_person_gender ? "Nam" : "Nữ"
    }

    return value;
}

class MerchantList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                { name: 'name', title: 'Name' },
                { name: 'code', title: 'code' },


                { name: 'contact_person_name', title: 'Họ và Tên ( Người đại diện)' },
                { name: 'contact_person_position', title: 'Vai trò/Chức vụ' },
                { name: 'contact_person_gender', title: 'Giới tính' },
                { name: 'contact_person_DOB', title: 'Ngày sinh' },
                { name: 'contact_id_number', title: 'Số CMND/CCCD/Hộ chiếu' },
                { name: 'contact_id_issue_date', title: 'Ngày cấp' },
                { name: 'contact_id_issue_place', title: 'Nơi cấp' },

                { name: 'contact_email', title: 'Email' },
                { name: 'contact_telephone', title: 'Số điện thoại' },

                { name: 'contact_commune', title: 'Phường/Xã' },
                { name: 'contact_district', title: 'Quận/Huyện' },
                { name: 'contact_province', title: 'Tỉnh/Thành' },



                // { name: 'contact_province', title: 'contact_province' },
                { name: 'locked', title: ' ' },
                { name: 'action', title: 'Options' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'name', align: 'center', width: 150 },
                { columnName: 'code', align: 'center', width: 100 },
                { columnName: 'contact_person_gender', align: 'center', width: 150},
                { columnName: 'contact_person_name', align: 'center', width: 250 },
                { columnName: 'contact_person_position', align: 'center', width: 300 },
                { columnName: 'contact_person_DOB', align: 'center', width: 250 },
                { columnName: 'contact_id_issue_date', align: 'center', width: 250 },
                { columnName: 'contact_id_issue_place', align: 'center', width: 250 },
                { columnName: 'contact_email', align: 'center', width: 250 },
                { columnName: 'contact_telephone', align: 'center', width: 250 },
                { columnName: 'contact_id_number', align: 'center', width: 250},
                { columnName: 'contact_commune', align: 'center', width: 250 },
                { columnName: 'contact_district', align: 'center', width: 250 },
                { columnName: 'contact_province', align: 'center', width: 250 },

                { columnName: 'locked', align: 'center', width: 20 },
                { columnName: 'action', align: 'left', width: 200 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'id', direction: 'asc' }],
            searchStatus: '',
            showImportMerchant: false
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        console.log("HISTORY: ", this.props.history);
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const { pageSize, currentPage, sorting } = this.state;

        let queryString = `${URL}?new=true&pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        if (columnSorting) {
            queryString = `${queryString}&orderby=${columnSorting.columnName}`;

            if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
            else queryString = `${queryString}&asc=true`;
        }

        return queryString;
    }

    loadData() {
        this.setState({
            loading: true
        });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-admin-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }
                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.loadData();
            }
        );
    };

    handleShowImportMerchant = () => {
        this.setState({ showImportMerchant: true });
    };

    handleCloseImportMerchant = () => {
        this.setState({ showImportMerchant: false });
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New Merchant"
                    component={Link}
                    to={'/merchant/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Create New Merchant</span>
                </Button>{' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>

                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import Merchant"
                    component={Link}
                    to={'/import/merchant'}
                >
                    <Icon>cloud_upload</Icon>
                    <span style={{ marginLeft: 10 }}>Import Merchant</span>
                </Button>{' '}

                <DataTable
                    rows={rows}
                    columns={columns}
                    columnWidths={tableColumnExtensions}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    loading={loading}
                    sorting={sorting}
                    totalCount={totalCount}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                    changeSorting={this.changeSorting}
                    CellComponent={CellComponent}
                />

                <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
                    <Route exact path="/merchant/create" component={CreateMerchant} />
                    <Route exact path="/merchant/edit/:id" component={EditMerchant} />
                    <Route exact path="/merchant/delete/:id" component={DeleteMerchant} />
                    <ImportMerchant open={this.state.showImportMerchant} onClose={this.handleCloseImportMerchant} {...this.props} />
                </ToastProvider>
            </div>
        );
    }
}

MerchantList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },

    cell_button: {
        width: 100,
        marginLeft: 2,
        marginRight: 2
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 2,
                    justifyContent: 'center'
                }}>
                <span>
                    <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/merchant/edit/' + this.props.row.id}
                    >
                        <Icon>edit</Icon>
                    </Button>
                    <Button
                        color="secondary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        to={'/merchant/delete/' + this.props.row.id}
                    >
                        <Icon>remove_circle</Icon>
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2
            }}
        />
    );
};

export default withStyles(styles)(MerchantList);
